import React, { useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../config/FirebaseConfiguration";
import LayoutContainer from "../../../containers/Layout";
import CardsData from "../../../provider/CardsData";
import CardButton from "../../../components/CardButton";
import PersonalCardInfo from "../../../containers/CardInfoContainer/PersonalCardInfo";
import { pickUpCardNumber, ReversedOrNot } from "../../../utils/generateNumber";
import { useQueryClient } from "@tanstack/react-query";
import { newQuestionForm } from "../../Questions/QuestionsUtils";
import { useAuthContext } from "../../../provider/Login/AuthProvider";
import MetaTags from "../../../components/MetaTags";

export default function ThreeCardsLayout({ children }) {
  const [cardsNumbers, setCardsNumbers] = useState(() => []);
  const [userQuestion, setUserQuestion] = useState(() => "");
  const [cardsHolder, setCardsHolder] = useState(() => ({
    past: {
      time: "past",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    present: {
      time: "present",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    future: {
      time: "future",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
  }));

  const { data: cards } = CardsData();
  const queryClient = useQueryClient();
  const userData = useAuthContext();
  //const userData = queryClient.getQueryData(["userData"]);

  function cardClicked(time) {
    let newQuestion = newQuestionForm(
      userData,
      `Tell me more about my ${time}`
    );
    setUserQuestion(`Tell me more about my ${time}`);
    //saveQuestion.mutate(newQuestion);
    queryClient.setQueryData(["userQuestion"], newQuestion);
    let randomCard = pickUpCardNumber(cardsNumbers);

    setCardsNumbers((prev) => [...prev, randomCard]);
    let cardChosen = cards[randomCard];

    setCardsHolder((prevCards) => ({
      ...prevCards,
      [time]: {
        time: time,
        card: cardChosen,
        isRevealed: true,
        isReversed: ReversedOrNot(),
      },
    }));
    logEvent(analytics, "click_three_cards", "button");
  }

  return (
    <LayoutContainer>
      <h1>Get Clarity for Past,Present and Future</h1>
      <div className="page-container">
        <div className="guide">
          This 3 cards Tarot spread is a quick and simple way to get insight
          into the past, present and future.
        </div>
        <h2>1. The Past</h2>
        <div className="guide">
          <ul>
            <li>
              Energies and events that are in the past yet still effect you
            </li>
            <li>
              How your past either holds you back (blocks) or helps you move
              forward (growth)?
            </li>
            <li>
              What you need to take from the past and use to your advantage
              today?
            </li>
          </ul>
        </div>
        {!cardsHolder.past.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton onClick={() => cardClicked(cardsHolder.past.time)} />
          </div>
        )}
        {cardsHolder.past.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.past.card}
            personalMeaning={1}
            isReversed={cardsHolder.past.isReversed}
            userQuestion={userQuestion}
          />
        )}
        <h2>2.The Present</h2>
        <div className="guide">
          <ul>
            <li>What is going on for you right now?</li>
            <li>The energy of the present moment</li>
            <li>
              Opportunities and challenges that are currently being presented to
              you
            </li>
          </ul>
        </div>
        {!cardsHolder.present.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton onClick={() => cardClicked(cardsHolder.present.time)} />
          </div>
        )}
        {cardsHolder.present.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.present.card}
            personalMeaning={0}
            isReversed={cardsHolder.present.isReversed}
            userQuestion={userQuestion}
          />
        )}
        <h2>3. The Future</h2>
        <div className="guide">
          <ul>
            <li>Outcome of situation</li>
            <li>The direction that things seem to be moving in</li>
            <li>What do you want?</li>
          </ul>
        </div>
        {!cardsHolder.future.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton onClick={() => cardClicked(cardsHolder.future.time)} />
          </div>
        )}
        {cardsHolder.future.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.future.card}
            personalMeaning={2}
            isReversed={cardsHolder.future.isReversed}
            userQuestion={userQuestion}
          />
        )}
        <div className="guide">
          <h2>Look closer at each card and ask yourself what it represents:</h2>
          <ul>
            <li>Some possibilities are…..</li>
            <li>A person in your life</li>
            <li>A situation</li>
            <li>Your emotions / feelings</li>
            <li>Your thoughts</li>
            <li>You and the way you are approaching things</li>
            <li>A new way of seeing things</li>
            <li>A possible solution</li>
          </ul>
        </div>
      </div>
      <MetaTags
        description={
          "Explore your past, present, and future with Tarot card readings. Discover the secrets of your life's journey and gain clarity for what lies ahead. Unlock the wisdom of Tarot in one reading."
        }
      />
    </LayoutContainer>
  );
}
