import { useEffect, useMemo } from "react";
import { calculateFeelings } from "../utils";
import styles from "./styles.css";
export default function StatisticsDisplay({
  selectedYear,
  selectedMonth,
  yearlyStatistics,
  stats,
  isYearlyOnly,
  personalizedCards,
}) {
  const totalNumberOfCards = useMemo(() => {
    return isYearlyOnly ? yearlyStatistics : stats.numberOfCardsInMonth;
  }, [isYearlyOnly, yearlyStatistics, stats]);

  const timeOfStatists = useMemo(() => {
    return isYearlyOnly ? "year" : "month";
  }, [isYearlyOnly]);
  useEffect(() => {
    const timer = setTimeout(() => {
    }, 100000);

    return () => clearTimeout(timer);
  }, [selectedYear, selectedMonth, yearlyStatistics, stats]);

  return (
    <div className="autobiography">
      <div className="year-section">
        <h2>Your mood by Cards Tarot</h2>
        You had in total
        <br /> In {selectedYear.label} year: {yearlyStatistics}  {" "} cards
      </div>
      {!isYearlyOnly && (
        <div className="year-section">
          In {selectedMonth.label}: {totalNumberOfCards}
        </div>
      )}
      <div>
        <ul>
          <li>
            <span className="important">☕ Cups: {stats.numberOfCups} </span>-
            You had{" "}
            <span className="important">
              {calculateFeelings(totalNumberOfCards, stats.numberOfCups)}
            </span>{" "}
            emphasis on emotions , relationships, and matters of the heart in
            this {timeOfStatists}.
          </li>
          <li>
            <span className="important">
              {" "}
              💰 Pentacles : {stats.numberOfPentacles}{" "}
            </span>
            - You had{" "}
            <span className="important">
              {calculateFeelings(totalNumberOfCards, stats.numberOfPentacles)}
            </span>{" "}
            focus on material and practical matters in this {timeOfStatists}.
          </li>
          <li>
            <span className="important">
              {" "}
              🌟 Major Arcana : {stats.numberOfMajorArcana}{" "}
            </span>
            - You had{" "}
            <span className="important">
              {calculateFeelings(totalNumberOfCards, stats.numberOfMajorArcana)}
            </span>{" "}
            emphasis on significant life events, major life lessons, and
            profound spiritual or personal experiences in this {timeOfStatists}.
          </li>
          <li>
            <span className="important">🌿 Wands : {stats.numberOfWands}</span>-
            You had{" "}
            <span className="important">
              {calculateFeelings(totalNumberOfCards, stats.numberOfWands)}
            </span>{" "}
            emphasis on action, ambition, and creative energy in this{" "}
            {timeOfStatists}.
          </li>
          <li>
            <span className="important">
              🗡️ Swords : {stats.numberOfSwords}
            </span>
            - You had{" "}
            <span className="important">
              {" "}
              {calculateFeelings(totalNumberOfCards, stats.numberOfSwords)}
            </span>{" "}
            focus on thoughts, mental processes, and the challenges or conflicts
            you may be facing in this {timeOfStatists}.
          </li>
        </ul>
        <h2>Here are the repeated cards</h2>
        {personalizedCards.length>=1 ? (
          <div className="image-container">
           
            <span className="important">
            {" "}
              {personalizedCards[0].cardName} :{personalizedCards[0].cardsCount}
            </span>{" "}
            <img
              className="card-img-personal"
              src={personalizedCards[0].cardImage}
              alt="card"
              css={styles}
            />
          - {personalizedCards[0].interpretation}
          </div>
        ): <div> You don't have any repeated card </div>}
        {personalizedCards.length >= 2 && (
          <div className="image-container">
           
            <span className="important">
              {" "}
              {personalizedCards[1].cardName} :{" "}
              {personalizedCards[1].cardsCount}
            </span>{" "}
            <img
              className="card-img-personal"
              src={personalizedCards[1].cardImage}
              alt="card"
              css={styles}
            />
            - {personalizedCards[1].interpretation}
          </div>
        )}
        {personalizedCards.length >= 3 && (
          <div className="image-container" >
           
            <span className="important">
              {" "}
              {personalizedCards[2].cardName}: {personalizedCards[2].cardsCount}
            </span>{" "}
            <img
              className="card-img-personal"
              src={personalizedCards[2].cardImage}
              alt="card"
              css={styles}
            />
            -{personalizedCards[2].interpretation}
          </div>
        )}
      </div>
    </div>
  );
}
