import { useState, useMemo } from "react";
import LayoutContainer from "../../containers/Layout";
import SelectDropDown from "../../components/SelectDropDown";
import SearchResultsContainer from "../../containers/SearchResults";
import AutosuggestWords from "../../components/Autosuggest";
import CardsData, {
  useGetCardsKeywordsData,
  useGetCardsNamesData,
} from "../../provider/CardsData";
import styles from "./styles.css";

export default function SearchCards() {
  /**
   * All the functions related to the search
   */
  const [searchInput, setSearchInput] = useState("");
  const [selectedDropDown, setSelectedDropDown] = useState({
    value: "name",
    label: "Name",
  });
  const [areCardsFiltered, setAreCardsFiltered] = useState(false);

  const { data: cards } = CardsData();
  const { data: keyWords } = useGetCardsKeywordsData();
  const { data: cardsName } = useGetCardsNamesData();

  const filteredCards = useMemo(() => {
    if (searchInput.length > 0) {
      switch (selectedDropDown.value) {
        case "name":
          setAreCardsFiltered(true);
          return cards.filter((card) => {
            return card.name.toLowerCase().match(searchInput.toLowerCase());
          });

        case "keyword":
          setAreCardsFiltered(true);
          return cards.filter((card) => card.keywords.includes(searchInput));
        default:
          return [];
      }
    }
  }, [searchInput, cards, selectedDropDown.value]);

  const options = [
    { value: "name", label: "Name" },
    { value: "keyword", label: "Keyword" },
  ];
  return (
    <LayoutContainer>
      <div className="guide">
        <h2>Make a search by card name or by keyword</h2>
      </div>
      <div className="search-container" css={styles}>
        <SelectDropDown options={options} setValues={setSelectedDropDown} />
        <AutosuggestWords
          searchSuggestion={
            selectedDropDown.value === "name" ? cardsName : keyWords
          }
          determineSearchValue={setSearchInput}
          setAreCardsFiltered={setAreCardsFiltered}
        />
      </div>

      {areCardsFiltered && (
        <SearchResultsContainer
          searchedValue={searchInput}
          filteredCads={filteredCards}
        />
      )}
    </LayoutContainer>
  );
}
