export const newQuestionForm = (userData, question) => {
  const currentTimestampMilliseconds = Date.now();
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1; // months are zero-based, so we add 1
  const day = now.getDate();

  let newQuestion = {
    userID: userData ? userData.uid : "no_user",
    year: year,
    month: month,
    date: `${year}-${month}-${day}`,
    dateTime: `${currentTimestampMilliseconds}`,
    question: question,
    cards: [],
    cardsImg: [],
    suits: [],
    isReversed: [],
    numberOfCards: 0,
    answers: [],
  };

  return newQuestion;
};


