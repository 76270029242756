import React from "react";
import styles from "./styles.css";
import { generateId } from "../../../utils/generateNumber";

export default function FullCardInfo(props) {
  return (
    <div className="one-card-reveal">
      <h2>{props.chosenCard.name}</h2>
      <div className="meaning-title">
        Description:
        <ul>
          <li>{props.chosenCard.description}</li>
        </ul>
      </div>
      <div className="one-card-display" css={styles}>
        <img className="card-image" src={props.chosenCard.img} alt="chosen card" />

        <div className="meanings-of-card" css={styles}>
          <div className="meaning-general">
            <div className="meaning-title">
              Suit:
              <ul>
                <li>{props.chosenCard.suit}</li>
              </ul>
            </div>

            <div className="meaning-title">
              KeyWords:
              <ul>
                {props.chosenCard.keywords.map((keyword) => (
                  <li key={generateId()}>{keyword}</li>
                ))}
              </ul>
            </div>
            {(props.chosenCard.arcana === "Major Arcana" ||
              props.chosenCard.number > 10) && (
              <div className="meaning-title">
                Elemental:
                <ul>
                  <li>{props.chosenCard.elemental}</li>
                </ul>
              </div>
            )}
            {props.chosenCard.arcana === "Major Arcana" ? (
              <div className="meaning-of-major-arcana">
                {" "}
                <div className="meaning-title">
                  Archetype:
                  <ul>
                    <li>{props.chosenCard.archetype}</li>
                  </ul>
                </div>
                <div className="meaning-title">
                  Numerology:
                  <ul>
                    <li>{props.chosenCard.numerology}</li>
                  </ul>
                </div>
                <div className="meaning-title">
                  Hebrew AlphaBet:
                  <ul>
                    <li>{props.chosenCard.hebrew_alphabet}</li>
                  </ul>
                </div>
                <div className="meaning-title">
                  Mythical and Spiritual:
                  <ul>
                    <li>{props.chosenCard.mythical_spiritual}</li>
                  </ul>
                </div>
              </div>
            ) : (
              <div className="meaning-of-minor-arcana">
                <div className="meaning-title">
                  Affirmation:
                  <ul>
                    <li>{props.chosenCard.affirmation}</li>
                  </ul>
                </div>
                {props.chosenCard.number <= 10 && (
                  <div className="meaning-title">
                    Astrology:
                    <ul>
                      <li>{props.chosenCard.astrology}</li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="meaning-wide">
            <div className="meaning-title">
              Personal Meaning:
              <ul>
                <li>{props.chosenCard.personal_meaning[0]}</li>
              </ul>
            </div>
            <div className="meaning-title">
              Fortune Telling:
              <ul>
                {props.chosenCard.fortune_telling.map((keyword) => (
                  <li key={generateId()}>{keyword}</li>
                ))}
              </ul>
            </div>
            <div className="meaning-title">
              Meaning:
              <ul>
                {props.chosenCard.meanings.light.map((keyword) => (
                  <li key={generateId()}>{keyword}</li>
                ))}
              </ul>
            </div>
            <div className="meaning-title">
              Interpretation:
              <ul>
                <li>{props.chosenCard.interpretation}</li>
              </ul>
            </div>
            <div className="meaning-title">
              Shadow Meaning:
              <ul>
                {props.chosenCard.meanings.shadow.map((keyword) => (
                  <li key={generateId()}>{keyword}</li>
                ))}
              </ul>
            </div>
            <div className="meaning-title">
              Reversed Meaning:
              <ul>
                <li>{props.chosenCard.reversed}</li>
              </ul>
            </div>
          </div>

          <div className="meaning-title">
            Questions to Ask Yourself:
            <ul>
              {props.chosenCard.questions_to_ask.map((keyword) => (
                <li key={generateId()}>{keyword}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
