import React from "react";
import { generateId } from "../../../utils/generateNumber";
import styles from "./styles.css";

const HalfCardInfo = (props) => {
  return (
    <div className="one-card-reveal">
      <h2>{props.chosenCard.name}</h2>
      {props.chosenCard.arcana === "Minor Arcana" && (
        <div className="meaning-affirmation">{props.chosenCard.affirmation}</div>
      )}
      <div className="one-card-display" css={styles}>
        <img className="card-image" src={props.chosenCard.img} alt="chosen card" />

        <div className="meanings-of-card" css={styles}>
          <div className="meaning meaning-wide">
            <div className="meaning-title">
              Fortune Telling:
              <ul>
                {props.chosenCard.fortune_telling.map((keyword) => (
                  <li key={generateId()}>{keyword}</li>
                ))}
              </ul>
            </div>
            <div className="meaning-title">
              Meaning:
              <ul>
                {props.chosenCard.meanings.light.map((keyword) => (
                  <li key={generateId()}>{keyword}</li>
                ))}
              </ul>
            </div>
            <div className="meaning meaning-title">
              Shadow Meaning:
              <ul>
                {props.chosenCard.meanings.shadow.map((keyword) => (
                  <li key={generateId()}>{keyword}</li>
                ))}
              </ul>
            </div>
          </div>
          <div className="meaning meaning-general">
            <div className="meaning-title">
              KeyWords:
              <ul>
                {props.chosenCard.keywords.map((keyword) => (
                  <li key={generateId()}>{keyword}</li>
                ))}
              </ul>
            </div>
            <div className="meaning meaning-title">
              Questions to Ask Yourself:
              <ul>
                {props.chosenCard.questions_to_ask.map((keyword) => (
                  <li key={generateId()}>{keyword}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HalfCardInfo;
