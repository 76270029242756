import React from "react";
import styles from "./styles.css";

export default function PageContainer({ children }) {
    return (
      <React.Fragment>
        <div className="page-container" css={styles}>
          {children}
        </div>
      </React.Fragment>
    );
  }