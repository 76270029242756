import firebaseApp from "../config/FirebaseConfiguration.js";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore/lite";
import { useQuery } from "@tanstack/react-query";
const db = getFirestore(firebaseApp);

export const loadQuestions = async (questionsCategory) => {
  const docRef = doc(db, "questions", questionsCategory);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // docSnap.data() will be undefined in this case
    console.log("No such document!");
  }
};

export function useGetGeneralQuestions(questionsCategory) {
  return useQuery({
    queryKey: ["allGeneralQuestions"],
    queryFn: () => loadQuestions(questionsCategory),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 5 * (60 * 1000), // 5 mins
    cacheTime: 10 * (60 * 1000), // 10 mins
  });
}

export const loadAllCategories = async () => {
  let arrayOfCategories = [];
  const questionsCollection = collection(db, "questions");
  const querySnapshot = await getDocs(questionsCollection);

  querySnapshot.forEach((doc) => {
    arrayOfCategories.push(doc.data());
  });

  return arrayOfCategories;
};

export function useGetGeneralCategories() {
  return useQuery({
    queryKey: ["allGeneralCategories"],
    queryFn: () => loadAllCategories(),
  });
}
