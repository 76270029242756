import React from "react";
import { RWebShare } from "react-web-share";
import { animateScroll as scroll } from "react-scroll";
import NavAppBar from "../../components/NavAppBar";
import PageContainer from "../PageLayout";
import styles from "./styles.css";
import { useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';

export default function LayoutContainer({ children }) {
  useEffect(() => {
    // Scroll to top when the component is mounted
    scroll.scrollToTop();
  }, []);

  return (
    <React.Fragment>
      <NavAppBar />
      <main className="container" css={styles}>
        <PageContainer>{children}</PageContainer>
        <footer>
          <RWebShare
            data={{
              text: "Hello, I want to tell you about the Tarot Diary website. It's completely free and offers guidance by combining Tarot cards with responses from AI.",
              url: "https://diarytarot.com",
              title: "Tarot Diary",
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <button className="button-share">Share 💬 </button>
          </RWebShare>
          <div className="social-share">
            <a
              href="https://www.facebook.com/wordtarotai"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://www.youtube.com/channel/UCZsKzHdv06yfY6n44PVujZg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faYoutube} />
            </a>
            <a
              href="https://twitter.com/diarytarotworld"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faTwitter} />
            </a>
          </div>
          <p className="copy-right">
            &copy; 2023 Tarot Diary. All rights reserved.
          </p>
        </footer>
      </main>
    </React.Fragment>
  );
}
