import firebaseApp from "../config/FirebaseConfiguration.js";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  setDoc,
} from "firebase/firestore/lite";
import { useQuery, useMutation } from "@tanstack/react-query";
const db = getFirestore(firebaseApp);

export const loadQuestions = async (userID) => {
  let arrayOfCards = [];
  if (userID !== "") {
    const questionsCollection = collection(db, "userData", userID, "questions");
    const querySnapshot = await getDocs(questionsCollection).then();

    querySnapshot.forEach((doc) => {
      arrayOfCards.push(doc.data());
    });

    return arrayOfCards;
  } else return arrayOfCards;
};

export function useGetQuestions(userID) {
  return useQuery({
    queryKey: ["allQuestions"],
    queryFn: () => loadQuestions(userID),
  });
}
export function useCreateQuestion() {
  return useMutation(
    ({
      userID,
      year,
      month,
      date,
      dateTime,
      question,
      cards,
      cardsImg,
      suits,
      isReversed,
      numberOfCards,
      answers,
    }) => {
      const docRef = doc(db, "userData", userID, "questions", dateTime);
      return setDoc(
        docRef,
        {
          question: question,
          dateTime: dateTime,
          date: date,
          year: year,
          month: month,
          cards: cards,
          cardsImg: cardsImg,
          suits: suits,
          isReversed: isReversed,
          numberOfCards: numberOfCards,
          answers: answers,
        },
        { merge: true }
      );
    }
  );
}
