import React from "react";
import HalfCardInfo from "../CardInfoContainer/HalfCardInfo";
import styles from "./styles.css";
import { generateId } from "../../utils/generateNumber";

const SearchResultsContainer = (props) => {
  return (
    <React.Fragment>
      <div className="info-cards-container" css={styles}>
        {props.filteredCads.map((card) => (
          <HalfCardInfo key={generateId()} chosenCard={card} />
        ))}
      </div>
    </React.Fragment>
  );
};

export default SearchResultsContainer;
