import React, { useState } from "react";
import CardButton from "../CardButton";

const TarotCard = ({ cardIndex, isShuffling, cardClicked }) => {
  const [activeCards, setActiveCards] = useState(Array(6).fill(false)); // Initialize an array with 9 cards, all set to false initially

  const handleTouchStart = () => {
    const newActiveCards = [...activeCards]; // Create a copy of the activeCards array
    newActiveCards[cardIndex - 1] = true; // Set the current card's index to true (active)
    setActiveCards(newActiveCards); // Update the state with the new array
  };

  const handleTouchEnd = () => {
    const newActiveCards = [...activeCards];
    newActiveCards[cardIndex - 1] = false; // Set the current card's index back to false (inactive)
    setActiveCards(newActiveCards);
  };

  return (
    <div
      className={`tarot-card card${cardIndex} ${isShuffling ? "shuffle" : ""} ${
        activeCards[cardIndex - 1] ? "active" : ""
      }`}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      <CardButton onClick={cardClicked} />
    </div>
  );
};

export default TarotCard;
