import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth} from "firebase/auth";
import { getFunctions } from 'firebase/functions';


const firebaseConfig = {
  apiKey: "AIzaSyDh6zjrXs-_9bjhJ2trE1D1NrWnh5di5b8",
  authDomain: "tarot-world-rws-deck.firebaseapp.com",
  databaseURL: "https://tarot-world-rws-deck-default-rtdb.firebaseio.com",
  projectId: "tarot-world-rws-deck",
  storageBucket: "tarot-world-rws-deck.appspot.com",
  messagingSenderId: "981642421927",
  appId: "1:981642421927:web:22da94341950fd5075dd82",
  measurementId: "G-GNXNRMPRHJ",
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
export const analytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);
export const functionsConfig = getFunctions(firebaseApp);
//connectAuthEmulator(auth, "http://localhost:9099");
//connectFirestoreEmulator(auth, "http://localhost:8080");
export default firebaseApp;
