import { AuthErrorCodes } from "firebase/auth";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LayoutContainer from "../../containers/Layout";
import { login } from "../../provider/Login/LoginEmailPassword";
import { handleSignInWithGoogle } from "../../provider/Login/LoginGoogleFacebookAuthentincation";
import { auth } from "../../config/FirebaseConfiguration";
import { signOut } from "firebase/auth";
import { useQueryClient } from "@tanstack/react-query";
import CustomLink from "../../components/Links";
import { useAuthContext } from "../../provider/Login/AuthProvider";
import googelestylesbutton from "../Login/googlestylesbutton.css";
import GoogleLogo from "./GoogleLogo";
import ButtonGeneral from "../../components/Button";

import styles from "./styles.css";

const Login = () => {
  const queryClient = useQueryClient();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  //const userData = queryClient.getQueryData(["userData"]);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const userData = useAuthContext();
  let userQuestion = queryClient.getQueryData(["userQuestion"]);

  const handleLogin = async () => {
    if (email === "" || password === "") {
      setError("Please fill in all fields");
    } else {
      try {
        await login(email, password);
        setIsUserLoggedIn(true);
        if (userQuestion) {
          navigate("/saved-card");
        } else navigate("/reading-one-card");
      } catch (error) {
        if (error.message === "Firebase: Error (auth/user-not-found).") {
          setError("The email doesn't exists. Type a new email");
        } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
          setError("The email is invalid. Type a new email");
        } else if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
          setError("Wrong password! Try once again.");
        } else {
          setError(error.message);
        }
      }
    }
  };

  const handleLogout = async () => {
    await signOut(auth);
    setIsUserLoggedIn(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (userData) {
      if (userQuestion) {
        navigate("/saved-card");
      } else navigate("/");
    }
  }, [userData, navigate, userQuestion]);
  return (
    <LayoutContainer>
      <h1>Login</h1>
      <div css={styles}>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {!userData && (
          <form className="form-to-login">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <div className="checkbox-container">
              <label htmlFor="showPasswordCheckbox">
                {showPassword ? "Hide Password" : "Show Password"}{" "}
              </label>
              <input
                type="checkbox"
                id="showPasswordCheckbox"
                onChange={togglePasswordVisibility}
              />
            </div>

            <button type="button" className="auth_button" onClick={handleLogin}>
              Login
            </button>
          </form>
        )}
        <button
          className="gsi-material-button"
          onClick={handleSignInWithGoogle}
          style={googelestylesbutton}
        >
          <div className="gsi-material-button-state"></div>
          <div className="gsi-material-button-content-wrapper">
            <div className="gsi-material-button-icon">
              <GoogleLogo />
            </div>
            <span className="gsi-material-button-contents">
              Sign in with Google
            </span>
            <span style={{ display: "none" }}>Sign in with Google</span>
          </div>
        </button>
        <div>
          Are you new to Tarot Diary? Click on to registered
          <CustomLink to="/registered">Create Your Account</CustomLink>
        </div>
        <div>
          Forgot your password click on
          <CustomLink to="/forgot-password">Forgot Password</CustomLink>
          to send you a link to a renew your password
        </div>
      </div>

      {isUserLoggedIn && (
        <ButtonGeneral type="button" onClick={handleLogout}>
          Logout
        </ButtonGeneral>
      )}
      <div className="autobiography welcome-guide">
        <h2>New Features in Tarot Diary</h2>
        <p>
          Keep a comprehensive Tarot journal diary. Track card suits, repeated
          cards, and store your insights. Explore the patterns in your readings
          and unlock the wisdom of your Tarot journey.{" "}
          <a
            href="https://youtube.com/shorts/l5qT5bAuul8?feature=share"
            target="_blank" rel="noopener noreferrer"
          >
            Find more info about new feature in this video.{" "}
          </a>
        </p>
      </div>
    </LayoutContainer>
  );
};

export default Login;
