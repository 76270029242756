import { Link, useLocation } from "react-router-dom";
import styles from "./styles.css";

import { dateFormatting } from "../utils";
export default function QuestionsByDate({ sortedQuestions }) {
  const location = useLocation();
  return (
    <div className="questions-cards-container" css={styles}>
      <ul>
        {sortedQuestions.map((questionCard) => {
          const dateToDisplay = dateFormatting(questionCard.date);
          return (
            <li style={{ marginBottom: "10px" }} key={questionCard.dateTime}>
              <span style={{ marginRight: "10px" }}>{dateToDisplay}</span>
              <Link to={`${location.pathname}/${questionCard.dateTime}`}>
                {questionCard.question}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
