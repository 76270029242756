import firebaseApp from "../config/FirebaseConfiguration.js";
import { getFirestore, doc, getDoc } from "firebase/firestore/lite";
import { useQuery } from "@tanstack/react-query";
const db = getFirestore(firebaseApp);

export const loadCardsInter = async (userID) => {
    const docRef = doc(db, "cards_statistics_inter", "all-cards");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }

};

export function useGetPersonalizedCards(userID) {
  return useQuery({
    queryKey: ["cardPersonalized"],
    queryFn: () => loadCardsInter(userID),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 5 * (60 * 1000), // 5 mins
    cacheTime: 10 * (60 * 1000), // 10 mins
  });
}
