import React, { useState } from "react";
import Autosuggest from "react-autosuggest";
import styles from "./styles.css";

export default function AutosuggestWords(props) {
  const maxSuggestions = 7;

  function getSuggestions(value) {
    let wordsSuggestion = props.searchSuggestion;

    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : wordsSuggestion
          .filter((option) =>
            option.toLowerCase().includes(inputValue.toLowerCase())
          )
          .slice(0, maxSuggestions);
  }

  function getSuggestionValue(suggestion) {
    props.determineSearchValue(suggestion);
    return suggestion;
  }

  function renderSuggestion(suggestion) {
    return <span>{suggestion}</span>;
  }

  const [suggestionsCollection, setSuggestionCollection] = useState({
    value: "",
    suggestions: [],
  });

  // Autosuggest input field blur handler
  const onBlur = (event) => {
    // Set the value of the input field to an empty string
    setSuggestionCollection((prev) => ({
      ...prev,
      value: "",
    }));
  };
  function onChange(event, { newValue, method }) {
    setSuggestionCollection((prev) => ({
      ...prev,
      value: newValue,
    }));
  }

  function onSuggestionsFetchRequested({ value }) {
    setSuggestionCollection((prev) => ({
      ...prev,
      suggestions: getSuggestions(value),
    }));
  }

  function onSuggestionsClearRequested() {
    setSuggestionCollection((prev) => ({
      ...prev,
      suggestions: [],
    }));
  }

  const { value, suggestions } = suggestionsCollection;
  const inputProps = {
    placeholder: "Type search word",
    value,
    onChange: onChange,
    onBlur,
  };

  return (
    <React.Fragment>
      <div className="search-container">
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          css={styles}
        />
      </div>
    </React.Fragment>
  );
}
