import { nanoid } from "nanoid";

export function generateNumber() {
  return Math.ceil(Math.random() * 78);
}

export function pickUpCardNumber(cardsNumbers) {
  const allNumbers = Array.from({ length: 78 }, (_, index) => index);
  const filteredNumbers = allNumbers.filter(
    (element) => !cardsNumbers.includes(element)
  );
  const randomIndex = Math.ceil(Math.random() * filteredNumbers.length);
  return filteredNumbers[randomIndex];
}
export function generateId() {
  return nanoid();
}
export function ReversedOrNot() {
  return Math.random() < 0.5; //50% probability of getting true
}
