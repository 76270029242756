import Quiz from "react-quiz-component";
import { useNavigate } from "react-router-dom";
import QuizData from "./QuizData.json";
import LayoutContainer from "../../../containers/Layout";
import ButtonGeneral from "../../../components/Button";
import MetaTags from "../../../components/MetaTags";
import styles from "./styles.css";

export default function QuizzesPage() {
  const navigate = useNavigate();
  return (
    <LayoutContainer>
      <div className="quiz-container">
        <Quiz
          quiz={QuizData}
          css={styles}
          continueTillCorrect={true}
          showInstantFeedback={true}
        />
        <ButtonGeneral onClick={() => navigate("/learn-cards")}>
          Go Back to All Cards
        </ButtonGeneral>
        <MetaTags
          description={
            "Test your Tarot knowledge with our engaging Tarot card quiz. Challenge yourself and deepen your understanding of the cards. Are you ready to embrace the mystic journey?"
          }
        />
      </div>
    </LayoutContainer>
  );
}
