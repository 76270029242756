import React, { useState } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../config/FirebaseConfiguration";
import LayoutContainer from "../../../containers/Layout";
import CardsData from "../../../provider/CardsData";
import CardButton from "../../../components/CardButton";
import PersonalCardInfo from "../../../containers/CardInfoContainer/PersonalCardInfo";
import { pickUpCardNumber, ReversedOrNot } from "../../../utils/generateNumber";
import { useQueryClient } from "@tanstack/react-query";
import { newQuestionForm } from "../../Questions/QuestionsUtils";
import { useAuthContext } from "../../../provider/Login/AuthProvider";
import MetaTags from "../../../components/MetaTags";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

export default function SevenCardsLayout({ children }) {
  const [cardsNumbers, setCardsNumbers] = useState(() => []);
  const [userQuestion, setUserQuestion] = useState(() => "");
  const [cardsHolder, setCardsHolder] = useState(() => ({
    card1: {
      meaning: "Who I am at the current moment?",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card2: {
      meaning:
        "What are the challenges that I have to become who I need to be?",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card3: {
      meaning: "What the hidden influences that affect my journey?",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card4: {
      meaning: "How past experiences shape my current path?",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card5: {
      meaning: "Who I aim to become in one year?",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card6: {
      meaning: "What should I do to become what I aim to be in one year?",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
    card7: {
      meaning: "What are going to be my achievements in one year?",
      card: {},
      isRevealed: false,
      isReversed: false,
    },
  }));

  const { data: cards } = CardsData();
  const queryClient = useQueryClient();
  const userData = useAuthContext();
  //const userData = queryClient.getQueryData(["userData"]);

  function cardClicked(meaning, numberOfCard) {
    let newQuestion = newQuestionForm(userData, meaning);
    setUserQuestion(meaning);
    queryClient.setQueryData(["userQuestion"], newQuestion);

    let randomCard = pickUpCardNumber(cardsNumbers);

    setCardsNumbers((prev) => [...prev, randomCard]);
    let cardChosen = cards[randomCard];

    setCardsHolder((prevCards) => ({
      ...prevCards,
      [numberOfCard]: {
        meaning: meaning,
        card: cardChosen,
        isRevealed: true,
        isReversed: ReversedOrNot(),
      },
    }));
    logEvent(analytics, "click_seven_cards", "button");
  }

  const navigate = useNavigate();

  async function saveCard(chosenCard, isReversed) {
    let userQuestion = queryClient.getQueryData(["userQuestion"]);
    userQuestion.cards.push(chosenCard.name);
    userQuestion.cardsImg.push(chosenCard.img);
    userQuestion.suits.push(chosenCard.suit);
    userQuestion.isReversed.push(isReversed);
    userQuestion.numberOfCards = userQuestion.numberOfCards + 1;
    queryClient.setQueryData(["userQuestion"], userQuestion);
    logEvent(analytics, "click_save_button", "button");
    if (!userData) {
      navigate("/login");
    } else {
      navigate("/saved-card");
    }
  }
  return (
    <LayoutContainer>
      <h1>Seven Layout Spread</h1>
      <div className="page-container">
        <div className="guide">
          This seven-card Tarot spread offers a quick and straightforward way to
          gain insights into your goals for the coming year. It reveals what you
          need to know and aim for in the year ahead.
        </div>
        <div className="guide">
          <h2>Look closer at each card and ask yourself what it represents:</h2>
          <ul>
            <li>Some possibilities are…..</li>
            <li>A person in your life</li>
            <li>A situation</li>
            <li>Your emotions / feelings</li>
            <li>Your thoughts</li>
            <li>You and the way you are approaching things</li>
            <li>A new way of seeing things</li>
            <li>A possible solution</li>
          </ul>
        </div>
        <h2>1. The Present Self</h2>
        <div className="guide">
          <ul>
            <li>This card reflects who you are at the current moment.</li>
          </ul>
        </div>
        {!cardsHolder.card1.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card1.meaning, "card1")}
            />
          </div>
        )}
        {cardsHolder.card1.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card1.card}
            personalMeaning={1}
            isReversed={cardsHolder.card1.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card1.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card1.card, cardsHolder.card1.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <h2>2. The Challenge</h2>
        <div className="guide">
          <ul>
            <li>
              This card represents immediate challenges to becoming who you need
              to be.
            </li>
          </ul>
        </div>
        {!cardsHolder.card2.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card2.meaning, "card2")}
            />
          </div>
        )}
        {cardsHolder.card2.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card2.card}
            personalMeaning={0}
            isReversed={cardsHolder.card2.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card2.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card2.card, cardsHolder.card2.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <h2>3. The Subconscious Influences</h2>
        <div className="guide">
          <ul>
            <li>Hidden influences that affect your journey.</li>
          </ul>
        </div>
        {!cardsHolder.card3.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card3.meaning, "card3")}
            />
          </div>
        )}
        {cardsHolder.card3.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card3.card}
            personalMeaning={2}
            isReversed={cardsHolder.card3.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card3.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card3.card, cardsHolder.card3.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <h2>4. The Past</h2>
        <div className="guide">
          <ul>
            <li>
              This card sheds light on how past experiences shape your current
              path.
            </li>
          </ul>
        </div>
        {!cardsHolder.card4.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card4.meaning, "card4")}
            />
          </div>
        )}
        {cardsHolder.card4.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card4.card}
            personalMeaning={2}
            isReversed={cardsHolder.card4.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card4.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card4.card, cardsHolder.card4.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <h2>5. The Desired Outcome</h2>
        <div className="guide">
          <ul>
            <li>Who I aim to become in one year.</li>
          </ul>
        </div>
        {!cardsHolder.card5.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card5.meaning, "card5")}
            />
          </div>
        )}
        {cardsHolder.card5.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card5.card}
            personalMeaning={2}
            isReversed={cardsHolder.card5.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card5.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card5.card, cardsHolder.card5.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <h2>6. The Immediate Next Steps</h2>
        <div className="guide">
          <ul>
            <li>This card advises on what to do next.</li>
          </ul>
        </div>
        {!cardsHolder.card6.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card6.meaning, "card6")}
            />
          </div>
        )}
        {cardsHolder.card6.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card6.card}
            personalMeaning={2}
            isReversed={cardsHolder.card6.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card6.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card6.card, cardsHolder.card6.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
        <h2>7. The Potential Future </h2>
        <div className="guide">
          <ul>
            <li>This is who you could become in one year's time.</li>
          </ul>
        </div>
        {!cardsHolder.card7.isRevealed && (
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">Click On Card Below</div>
            <CardButton
              onClick={() => cardClicked(cardsHolder.card7.meaning, "card7")}
            />
          </div>
        )}
        {cardsHolder.card7.isRevealed && (
          <PersonalCardInfo
            chosenCard={cardsHolder.card7.card}
            personalMeaning={2}
            isReversed={cardsHolder.card7.isReversed}
            userQuestion={userQuestion}
          />
        )}
        {cardsHolder.card7.isRevealed && (
          <Button
            onClick={() =>
              saveCard(cardsHolder.card7.card, cardsHolder.card7.isReversed)
            }
          >
            Save Answer
          </Button>
        )}
      </div>
      <MetaTags
        description={
          "Explore your past, present, and future with Tarot card readings. Discover the secrets of your life's journey and gain clarity for what lies ahead. Unlock the wisdom of Tarot in one reading."
        }
      />
    </LayoutContainer>
  );
}
