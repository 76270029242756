import LayoutContainer from "../../containers/Layout";
import React from "react";

export default function Articles() {
  return (
    <LayoutContainer>
      <div className="autobiography">
        <h2 className="about-header">Topics to Think About</h2>
        <ul>
          <li>
            {" "}
            <a href="https://www.reddit.com/r/tarotChatGPT/comments/185ylkn/can_ai_replace_human_intuition_in_tarot_readings/">
              Can AI Replace Human Intuition in Tarot Readings?
            </a>
          </li>
          <li>
            {" "}
            <a href="https://www.reddit.com/r/tarotChatGPT/comments/17p9plr/embracing_tarot_how_this_ancient_practice_can/">
              Embracing Tarot: How This Ancient Practice Can Benefit Modern
              Lives
            </a>
          </li>
          <li>
            <a href="https://www.reddit.com/r/tarotChatGPT/comments/17pa3u2/the_depths_of_tarot_a_dive_into_the_esoteric/">
              The Depths of Tarot: A Dive into the Esoteric{" "}
            </a>
          </li>
          <li>
            {" "}
            <a href="https://www.reddit.com/r/tarotChatGPT/comments/17p9kpc/why_online_tarot_readings_with_ai_are_the_future/">
              Why Online Tarot Readings with AI are the Future of Spiritual
              Guidance{" "}
            </a>
          </li>
          <li>
            {" "}
            <a href="https://www.reddit.com/r/tarotChatGPT/comments/17pa9u6/riderwaite_tarot_the_iconic_imagery_that/">
            Rider-Waite Tarot: The Iconic Imagery That Revolutionized Tarot Reading {" "}
            </a>
          </li>
        </ul>
      </div>
    </LayoutContainer>
  );
}
