import firebaseApp from "../config/FirebaseConfiguration.js";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore/lite";
import { useQuery } from "@tanstack/react-query";
const db = getFirestore(firebaseApp);
// Create a client

export const loadCards = async () => {
  const cardsCollection = collection(db, "cards");
  const querySnapshot = await getDocs(cardsCollection).then();
  let arrayOfCards = [];
  querySnapshot.forEach((doc) => {
    arrayOfCards.push(doc.data());
  });
  return arrayOfCards;
};

export default function useGetCardsData() {
  return useQuery({
    queryKey: ["cards"],
    queryFn: loadCards,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 5 * (60 * 1000), // 5 mins
    cacheTime: 10 * (60 * 1000), // 10 mins
  });
}

const loadAllKeywords = async () => {
  const docRef = doc(db, "search", "keywords");
  const docSnap = await getDoc(docRef);
  return docSnap.data().keywords;
};

export function useGetCardsKeywordsData() {
  return useQuery({
    queryKey: ["cardsKeywords"],
    queryFn: loadAllKeywords,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 5 * (60 * 1000), // 5 mins
    cacheTime: 10 * (60 * 1000), // 10 mins
  });
}

const loadCardsName = async () => {
  const docRef = doc(db, "search", "cards-name");
  const docSnap = await getDoc(docRef);
  return docSnap.data().cards_name;
};

export function useGetCardsNamesData() {
  return useQuery({
    queryKey: ["cardsNames"],
    queryFn: loadCardsName,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    staleTime: 5 * (60 * 1000), // 5 mins
    cacheTime: 10 * (60 * 1000), // 10 mins
  });
}
