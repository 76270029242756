import Select from "react-select";

const SelectDropDown = (props) => {
 

  const styles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "antiquewhite" : "white",
    }),
  };
  return (
    <Select
      defaultValue={props.options[0]}
      options={props.options}
      onChange={props.setValues}
      styles={styles}
    />
  );
};

export default SelectDropDown;
