import { Navigate, Outlet } from "react-router-dom";
import { useAuthContext } from "../provider/Login/AuthProvider";

const PrivateRoutes = () => {
  //const queryClient = useQueryClient();
  //const isAuthenticated = queryClient.getQueryData(["userData"]); // Replace with your authentication logic
  const user = useAuthContext();

  return user ? <Outlet /> : <Navigate to="/" />;
};
export default PrivateRoutes;
