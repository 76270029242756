import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { useQueryClient } from "@tanstack/react-query";
import { analytics } from "../../../config/FirebaseConfiguration";
import LayoutContainer from "../../../containers/Layout";
import { generateNumber, ReversedOrNot } from "../../../utils/generateNumber";
import Button from "../../../components/Button";
import PersonalCardInfo from "../../../containers/CardInfoContainer/PersonalCardInfo";
import styles from "./styles.css";
import CardsData from "../../../provider/CardsData";
import CustomLink from "../../../components/Links";
import { useAuthContext } from "../../../provider/Login/AuthProvider";
import MetaTags from "../../../components/MetaTags";
import MyModal from "../../../components/Modal";
import TarotCard from "../../../components/TarotCard/TarotCard";

export default function OneCardLayout({ children }) {
  /**
   * States related to cards
   */
  const [cardLoaded, setCardLoaded] = useState(false);
  const [beforeCardLoaded, setBeforeCardLoaded] = useState(true);
  const [chosenCard, setChosenCard] = useState({});
  const [isReversed, setIsReversed] = useState(false);

  const { data: cards } = CardsData();

  /**
   * Modal
   */
  let [isOpen, setIsOpen] = useState(false);
  let [isNotLiked, setIsNotLiked] = useState(false);

  function closeModal(response) {
    setIsNotLiked(response === "click_not_liked");
    setIsOpen(response === "click_not_liked");
    logEvent(analytics, response, "button");
  }
  /**
   * Client Calls
   */
  const queryClient = useQueryClient();
  const userQuestion = queryClient.getQueryData(["userQuestion"]);
  const userData = useAuthContext();

  const navigate = useNavigate();

  function loadCardData() {
    const randomCard = generateNumber();
    let cardChosen = cards[randomCard];
    setChosenCard(() => cardChosen);
  }

  function cardClicked() {
    setBeforeCardLoaded(false);
    setCardLoaded(true);
    loadCardData();
    setIsReversed(ReversedOrNot()); //50% probability of getting true

    logEvent(analytics, "click_one_card", "button");
  }

  function hideCard() {
    setIsOpen(true);
    setCardLoaded(false);
    setBeforeCardLoaded(true);
    logEvent(analytics, "click_once_again", "button");
  }

  async function saveCard() {
    let userQuestion = queryClient.getQueryData(["userQuestion"]);
    userQuestion.cards.push(chosenCard.name);
    userQuestion.cardsImg.push(chosenCard.img);
    userQuestion.suits.push(chosenCard.suit);
    userQuestion.isReversed.push(isReversed);
    userQuestion.numberOfCards = userQuestion.numberOfCards + 1;
    queryClient.setQueryData(["userQuestion"], userQuestion);
    logEvent(analytics, "click_save_button", "button");
    if (!userData) {
      navigate("/login");
    } else {
      navigate("/saved-card");
    }
  }
  /**
   * Shuffling
   */
  const [isShuffling, setIsShuffling] = useState(false);

  const shuffleCard = () => {
    logEvent(analytics, "click_shuffle", "button");
    setIsShuffling(true);
    setTimeout(() => {
      setIsShuffling(false);
    }, 1000); // Adjust the duration to match your animation
  };

  return (
    <LayoutContainer>
      <h1>Get Advice </h1>
      {beforeCardLoaded && (
        <div className="one-card-spread-guide">
          {!userQuestion && (
            <p className="one-card-guide">
              It would be best to ask your question or choose from ready
              questions. After getting the question, click on the card below to
              draw a tarot card from the deck. By providing a question, you will
              get an answer from Artificial Intelligence. The card that is
              chosen will present you with lessons and wisdom. You can use this
              to stimulate more profound thought and get some inspiration.
            </p>
          )}
          {userQuestion && (
            <p className="asked_question" css={styles}>
              {" "}
              "{userQuestion.question}"
            </p>
          )}{" "}
          {!userQuestion && (
            <div>
              <ul>
                Choose:
                <CustomLink to="/my-questions">
                  Ask your own question
                </CustomLink>
                or
                <CustomLink to="/choose-category">Choose question</CustomLink>
              </ul>
            </div>
          )}
          {userQuestion && (
            <div className="guide-to-click">
              <div className="guide-to-click-the-card">
                Focus your attention on your question and vividly picture the
                situation or the person you're inquiring about.
              </div>
              <Button onClick={shuffleCard}>Shuffle</Button>
              <div>Choose one of the cards below</div>
              <div className="cards-shuffling-container">
                <TarotCard
                  cardIndex="1"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
                <TarotCard
                  cardIndex="2"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
                <TarotCard
                  cardIndex="3"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
                <TarotCard
                  cardIndex="4"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
                <TarotCard
                  cardIndex="5"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
                <TarotCard
                  cardIndex="6"
                  isShuffling={isShuffling}
                  cardClicked={cardClicked}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {userQuestion && !cardLoaded && (
        <div>
          Choose:
          <CustomLink to="/my-questions">Ask a new question</CustomLink>
          or
          <CustomLink to="/choose-category">Choose a new question</CustomLink>
        </div>
      )}
      {userQuestion && cardLoaded && (
        <div className="guide">
          <p className="asked_question"> "{userQuestion.question}"</p>
        </div>
      )}

      {cardLoaded && userQuestion && (
        <PersonalCardInfo
          chosenCard={chosenCard}
          personalMeaning={0}
          isReversed={isReversed}
          userQuestion={userQuestion.question}
        />
      )}

      {
        <MyModal
          isOpen={isOpen}
          closeModal={closeModal}
          isNotLiked={isNotLiked}
          saveCard={saveCard}
        />
      }
      {cardLoaded && (
        <div className="one-card-spread-guide mysticsense_recomendation">
          You can find more meaning in your interpretation by referring to the
          master in Tarot reading.
          <a className="button donate" href="https://www.mysticsense.com/chat-psychics/#a_aid=diarytarotworld&a_bid=206c45d4">
            {" "}
            More Meaning 🙏
          </a>
        </div>
      )}
      {cardLoaded && (
        <div className="one-card-spread-guide">
          <p className="one-card-guide">
         While our Tarot services are offered freely, they are the product of continuous dedication and passion. 
          </p>
          <a className="button donate"
            href="https://ko-fi.com/R5R7P2LKO"
            target="_blank"
            rel="noreferrer"
          >
            Donate 💖
          </a>
        </div>
      )}
      {cardLoaded && (
        <div className="buttons_display">
          <p>
            You can save your answer and read it later.
          </p>
          <Button onClick={saveCard}>Save Answer</Button>
          <Button onClick={hideCard}>Try Once Again</Button>
        </div>
      )}
      <MetaTags
        description={
          "Get personalized Tarot insights with AI ChatGPT. Ask any question, and receive expert Tarot interpretations instantly. Uncover answers to life's mysteries through the power of Tarot and AI."
        }
      />
    </LayoutContainer>
  );
}
