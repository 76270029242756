import { useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LayoutContainer from "../../../containers/Layout";
import { useGetQuestions } from "../../../provider/QuestionsData";
import { useAuthContext } from "../../../provider/Login/AuthProvider";
import Select from "react-select";
import Button from "../../../components/Button";
import QuestionsByDate from "../components/QuestionsByDate";

import {
  years,
  months,
  listOfMonths,
  currentYear,
  currentMonth,
} from "../utils";

export default function AllAskedQuestions() {
  const navigate = useNavigate();

  const userData = useAuthContext();

  const { data: allQuestions } = useGetQuestions(
    userData ? userData.uid : "no_user"
  );

  const [questionsDates, setQuestionsDates] = useState(() => []);

  const [selectedYear, setSelectedYear] = useState({
    value: currentYear,
    label: currentYear,
  });
  const [selectedMonth, setSelectedMonth] = useState({
    value: currentMonth,
    label: listOfMonths[currentMonth - 1],
  });

  //const location = useLocation();
  const questionsToDisplay = useMemo(() => {
    return <QuestionsByDate sortedQuestions={questionsDates} />;
  }, [questionsDates]);

  const buildQuestionDate = (filteredData) => {
    const sortedQuestions = filteredData.sort((a, b) => {
      const aDate = new Date(a.date);
      const bDate = new Date(b.date);

      if (isNaN(aDate) || isNaN(bDate)) {
        // Handle invalid or missing date values here
        return 0; // For example, consider them equal
      }
      //return bDate - aDate; // Sort in ascending orders
      return b.dateTime - a.dateTime; // Sort in ascending orders
    });
    if (filteredData.length > 0) {
      setQuestionsDates(sortedQuestions);
    } else {
      setQuestionsDates(() => []);
    }
  };

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    filterData(selectedOption, selectedMonth);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    filterData(selectedYear, selectedOption);
  };

  const filterData = (year, month) => {
    if (year && month) {
      const filtered = allQuestions.filter(
        (item) => item.year === year.value && item.month === month.value
      );

      buildQuestionDate(filtered);
    }
  };

  useEffect(() => {
    if (!questionsDates) {
      buildQuestionDate(questionsDates);
    }

    return () => {
      // Cleanup code here (e.g., removing event listeners)
    };
    // eslint-disable-next-line
  }, [userData, allQuestions, questionsDates, selectedMonth, selectedYear]);

  return (
    <LayoutContainer>
      <Button onClick={() => navigate("/my-tarot-diary")}>
        Go Back to My Diary
      </Button>{" "}
      <h2>Your Previously Asked Questions</h2>
      It shows the last saved cards. If you want to filter by month choose year
      and month.
      <div className="filter-selects">
        <label>Year</label>
        <Select
          value={currentYear}
          onChange={handleYearChange}
          options={years}
          placeholder={selectedYear.value}
        />
        <label>Month</label>
        <Select
          value={currentMonth}
          onChange={handleMonthChange}
          options={months}
          placeholder={listOfMonths[selectedMonth.value-1]}
        />
      </div>
      {userData && questionsDates.length > 0 ? (
        questionsToDisplay
      ) : (
        <div>Select year and month to show data</div>
      )}
    </LayoutContainer>
  );
}
