import React from "react";
import { Link } from "react-router-dom";
import LayoutContainer from "../containers/Layout";
import CustomLink from "../components/Links";
import { useQueryClient } from "@tanstack/react-query";
import { newQuestionForm } from "./Questions/QuestionsUtils";
import { logEvent } from "firebase/analytics";
import { analytics } from "../config/FirebaseConfiguration";
import { motion } from "framer-motion";
import cardImage from "../images/tarotPrediction.png";
import tarotCard from "../images/tarotCard.png";
import loveCard from "../images/couple2.png";
import futurePrediction from "../images/futurePrediction.png";
import questionmark from "../images/questionmark.png";
import questionCard from "../images/questionCard.png";
import person from "../images/person2.png";
import sevenLayout from "../images/sevenLayout.png";
import yesno from "../images/YesNo.png";
import year2024 from "../images/year2024.png";
import styles from "../pages/styles.css";
import MetaTags from "../components/MetaTags";
import { useAuthContext } from "../provider/Login/AuthProvider";

export default function Home() {
  const queryClient = useQueryClient();
  const userData = useAuthContext();

  const handleSubmit = (question) => {
    let newQuestion = newQuestionForm(userData, question);
    queryClient.setQueryData(["userQuestion"], newQuestion);
    logEvent(analytics, "daily_inspiration_question", "link");
  };

  return (
    <LayoutContainer>
      <MetaTags
        description={
          "Tarot reading online by AI and a personal Tarot journal diary in one place. Discover insights, track card patterns, and embark on a journey of wisdom with our unique Tarot experience."
        }
      />
      <motion.h1
        animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
        transition={{
          duration: 2,
          delay: 0.3,
          ease: [0.5, 0.71, 1, 1.5],
        }}
        initial={{ opacity: 0, scale: 0.5 }}
        whileHover={{ scale: 1.2 }}
      >
        Welcome!
      </motion.h1>
      <div className="frame-option">
        <Link className="align-center" to="/choose-category">
          <img className="card-img" src={cardImage} alt="magic starts here" />
          <h2>Do you want to know about today or tomorrow?</h2>{" "}
        </Link>
        <ul className="guide-links">
          <CustomLink
            to="/reading-one-card"
            onClick={() => handleSubmit("How is my day going to be today?")}
          >
            How is my day going to be today?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() => handleSubmit("What should I know about tomorrow?")}
          >
            What should I know about tomorrow?
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/my-questions">
          <h2>Ask any Question</h2>
          <img
            className="card-img"
            src={questionmark}
            alt="Personal Question"
          />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/my-questions">Ask any question</CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/reading-year-layout">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            2024 year By Month
          </motion.h2>
          <img className="card-img" src={year2024} alt="year questions" />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/reading-year-layout">
            Reveal your cards for each of the month in 2024
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/reading-seven-cards-layout">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Seven Cards Spread
          </motion.h2>
          <img
            className="card-img"
            src={sevenLayout}
            alt="Past, Present and Future"
          />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/reading-seven-cards-layout">
            Who I Need to Become in One Year? What will happen in one year?
          </CustomLink>
        </ul>{" "}
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/reading-yes-no-layout">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Yes,No or Maybe
          </motion.h2>
          <img className="card-img" src={yesno} alt="yes no or maybe" />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/reading-yes-no-layout">
            You can get an answer to Yes,No or Maybe
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <a
          className="align-center"
          href="https://www.mysticsense.com/5minfree/#a_aid=diarytarotworld&a_bid=e4f7441a"
        >
          <h2>Psychics, Tarot, Astrology and more</h2>
          <img className="card-img" src={person} alt="Psychics" />
        </a>
        <ul className="guide-links">
          <li>
            <a href="https://www.mysticsense.com/5minfree/#a_aid=diarytarotworld&a_bid=e4f7441a">
              Get Free 5 Minutes Psychic Reading with a real person.
            </a>
          </li>
        </ul>
      </div>

      <div className="autobiography welcome-guide" css={styles}>
        <h1>
          Welcome to Tarot AI - Your Pocket-sized Guide through Life's
          Mysteries!
        </h1>
        <section>
          <h2>Discover the Magic of Tarot AI</h2>
          <p>
            Ever wish you had a friend who’s always there, day or night, ready
            to offer insight when you need it most? Well, that’s exactly who we
            strive to be here at "Tarot Diary". Our digital doors are open 24/7,
            so whenever you seek guidance, a moment of reflection, or a glimpse
            into the possible future, we’re here for you - no waiting required.
          </p>
          <ul>
            <li>
              <strong>Ask a Personal Question:</strong> Have a pressing question
              on your mind? Just type it in, and our Tarot AI will provide you
              with insightful answers.
            </li>
            <li>
              <strong>Choose from a Variety of Questions:</strong> Not sure what
              to ask? We offer a variety of suggested questions to help you gain
              clarity on different aspects of your life, from love and career to
              spirituality and personal growth.
            </li>
            <li>
              <strong>Receive a Random Card:</strong> Want a quick glimpse into
              your destiny? Our Tarot AI can randomly select a card for you,
              offering guidance from the Tarot's mystical symbols and meanings.
            </li>
            <li>
              <strong>Explore the Past, Present, and Future:</strong> Delve
              deeper into your life's journey by drawing cards that represent
              your past, present, and future. Uncover hidden insights that can
              empower you to make informed decisions.
            </li>
            <li>
              <strong>Learn About Each Tarot Card:</strong> Curious about the
              meanings and symbolism behind each Tarot card? Our website
              provides comprehensive descriptions of every card in the deck, so
              you can deepen your understanding of the Tarot's rich history and
              significance.
            </li>
          </ul>
        </section>
        <section>
          <h2>How It Works</h2>
          <p>
            Our Tarot AI uses advanced algorithms to select cards at random,
            ensuring that each reading is unique and tailored to your specific
            question or situation. It's like having your own personal Tarot card
            reader at your fingertips, available 24/7.
          </p>
        </section>
        <section>
          <h2>Unlock the Mysteries of Your Life</h2>
          <p>
            Tarot has been used for centuries as a tool for self-discovery and
            spiritual growth. With our Tarot AI, you can embark on a journey of
            self-reflection and gain valuable insights that can guide you
            towards a brighter future.
          </p>
        </section>
        <section>
          <h2>Ready to Get Started?</h2>
          <p>
            Whether you're a seasoned Tarot enthusiast or a curious beginner,
            our Tarot AI is here to assist you on your quest for knowledge and
            enlightenment. Get started now by asking a question or exploring the
            world of Tarot cards.
          </p>
          <p>
            Remember, the cards may hold the answers you seek, but it's up to
            you to interpret their messages in a way that resonates with your
            life's path. Enjoy your Tarot journey with Tarot AI!
          </p>
        </section>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/choose-category">
          <h2>Different Kind of Questions</h2>
          <img
            className="card-img"
            src={questionCard}
            alt="Different Kind of Questions"
          />
        </Link>
        <ul className="guide-links">
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit(
                "What should I do in order to attract a soulmate to my life?"
              )
            }
          >
            What should I do in order to attract a soulmate to my life?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() => handleSubmit("What will happen if I say Yes?")}
          >
            What will happen if I say Yes?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() => handleSubmit("What does he think about me?")}
          >
            What does he think about me?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("How can I improve my financial situation?")
            }
          >
            How can I improve my financial situation?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit(
                "What can I expect in my career over the next six months?"
              )
            }
          >
            What can I expect in my career over the next six months?
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/reading-three-cards-layout">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Past, Present and Future
          </motion.h2>
          <img
            className="card-img"
            src={tarotCard}
            alt="Past, Present and Future"
          />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/reading-three-cards-layout">
            Ask about Past, Present and Future
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <h2>Would you like to know what will happen if you do...?</h2>
        <ul className="guide-links">
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("What will happen if I go to this trip?")
            }
          >
            What will happen if I go to this trip?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("What will happen if I start on this project?")
            }
          >
            What will happen if I start on this project?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("What will happen if I tell the truth?")
            }
          >
            What will happen if I tell the truth?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("What will happen if I cancel the meeting?")
            }
          >
            What will happen if I cancel the meeting?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("What will happen if I agree to sign the contract?")
            }
          >
            What will happen if I agree to sign the contract?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("What will happen if I follow my dream?")
            }
          >
            What will happen if I follow my dream?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("What will happen if I message to the person?")
            }
          >
            What will happen if I message to the person?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("What will happen if I make a call to the person?")
            }
          >
            What will happen if I make a call to the person?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() => handleSubmit("What will happen if I say NO?")}
          >
            What will happen if I say NO?
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <a
          className="align-center"
          href="https://www.mysticsense.com/Love-Psychics/#a_aid=diarytarotworld&a_bid=49c733c2"
        >
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Love and Relationship in Your Life
          </motion.h2>
          <img
            className="card-img love-img"
            src={loveCard}
            alt="Love and Relationship in Your Life"
          />
        </a>
        <a href="https://www.mysticsense.com/Love-Psychics/#a_aid=diarytarotworld&a_bid=49c733c2">
          5 Minutes Free Reading from Real Person
        </a>
        <ul className="guide-links">
          <CustomLink
            to="/reading-one-card"
            onClick={() => handleSubmit("What is he hiding from me?")}
          >
            What is he hiding from me?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() => handleSubmit("What does she think about me?")}
          >
            What does she think about me?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("What should I do to attract him to my life?")
            }
          >
            What should I do to attract him to my life?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit(
                "How can I enhance the quality of my current relationship?"
              )
            }
          >
            How can I enhance the quality of my current relationship?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit(
                "What will happen if I start the relationship with this person?"
              )
            }
          >
            What will happen if I start the relationship with this person?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("Why is it hard for me to find a soulmate?")
            }
          >
            Why is it hard for me to find a soulmate?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() => handleSubmit("What does he expect from me?")}
          >
            What does he expect from me?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() =>
              handleSubmit("What was the main reason for my last breakup?")
            }
          >
            What was the main reason for my last breakup?
          </CustomLink>
          <CustomLink
            to="/reading-one-card"
            onClick={() => handleSubmit("What does she expect from me?")}
          >
            What does she expect from me?
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/choose-category">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Topics About Finance, Health, Educations and more
          </motion.h2>
          <img
            className="card-img"
            src={futurePrediction}
            alt="Topics About Finance"
          />
        </Link>
        <ul className="guide-links">
          <CustomLink to="/choose-category">
            Choose question from different topics
          </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link className="align-center" to="/learn-cards">
          <motion.h2
            animate={{ x: [50, 150, 5], opacity: 1, scale: 1 }}
            initial={{ opacity: 0, scale: 0.5 }}
            whileHover={{ scale: 1.2 }}
          >
            Get More Inshights About Tarot
          </motion.h2>
        </Link>
        <p className="guide">
          Tarot cards are a great way to gain insight into yourself and the
          world around you. You can explore the Tarot card universe and learn
          about each card in detail.
        </p>
        <ul className="guide-links">
          <CustomLink to="/learn-cards">Learn Cards</CustomLink>
          <CustomLink to="/learn-cards/quizzes">
            Test your Knowledge for Tarot Cards
          </CustomLink>
          <CustomLink to="/search-cards">Search for Cards</CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link to="/support-me">
          <h2>Support for Tarot Diary</h2>
        </Link>
        <ul className="guide-links">
          <CustomLink to="/support-me">Buy me a Coffee ☕ </CustomLink>
        </ul>
      </div>
      <div className="frame-option">
        <Link to="/about-me">
          <h2>About Me and Tarot Diary</h2>
        </Link>
        <ul className="guide-links">
          <CustomLink to="/about-me">Get to know me</CustomLink>
        </ul>
      </div>
    </LayoutContainer>
  );
}
