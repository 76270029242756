import React from 'react';
import { Helmet } from 'react-helmet-async';

const MetaTags = ({description }) => {
  return (
    <Helmet>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default MetaTags;