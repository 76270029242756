import { useQuery } from "@tanstack/react-query";
import { generateChatCompletion } from "../server/servercalls.js";

export async function getChatAPIResponse2(card, question, isReversed) {
  const requestData = {
    card: card,
    question: question,
    isReversed: isReversed,
  };
  try {
    const response = await generateChatCompletion(requestData);
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export function useGetChatGPTResponse(card, question, isReversed) {
  return useQuery({
    queryKey: ["ChatGPTResponse"],
    queryFn: () => getChatAPIResponse2(card, question, isReversed),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
