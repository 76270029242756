import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import LayoutContainer from "../../../containers/Layout";
import Button from "../../../components/Button";
import styles from "./styles.css";
import { newQuestionForm } from "../QuestionsUtils";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../config/FirebaseConfiguration";
import { useAuthContext } from "../../../provider/Login/AuthProvider";

function MyQuestions() {
  const queryClient = useQueryClient();
  //const userData = queryClient.getQueryData(["userData"]);
  const userData = useAuthContext();
  const navigate = useNavigate();
  const [textareaValue, setTextareaValue] = useState("");

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let userTypedQuestion = event.target.question.value;

    // Security checks
    var patterns = [
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, // XSS check
      /<[^>]+>/gi, // Basic HTML tags check
      /(;|--|\/\*|\*\/|@@|@|char|nchar|varchar|nvarchar|alter|begin|cast|create|cursor|declare|delete|drop|end|exec|execute|fetch|insert|kill|open|select|sys|sysobjects|syscolumns|table|update)/gi // SQL injection check
  ];

    let isValid = true;
    for (var i = 0; i < patterns.length; i++) {
      if (patterns[i].test(textareaValue)) {
        isValid = false;
        break;
      }
    }
    if (!isValid) {
      userTypedQuestion = "Your question is invalid";
    }

    let newQuestion = newQuestionForm(userData, userTypedQuestion);
    queryClient.setQueryData(["userQuestion"], newQuestion);
    logEvent(analytics, "ask_question", "button");
    navigate("/reading-one-card");
  };

  const isButtonDisabled = textareaValue === "";
  const buttonClassName = `button ${isButtonDisabled ? "inactive" : ""}`;

  return (
    <LayoutContainer>
      <div className="page-container-guide" css={styles}>
        <h1>Ask Question</h1>
        <form
          onSubmit={handleSubmit}
          className="page-container-guide"
          css={styles}
        >
          <div className="textarea-container">
            <label for="question" className="label-for-text-field">
              Type your question:
              <textarea
                className="text-field"
                name="question"
                id="question"
                onChange={handleTextareaChange}
              />
            </label>
          </div>
          <Button disabled={isButtonDisabled} className={buttonClassName}>
            Ask Question
          </Button>
          <Button onClick={() => navigate("/reading-one-card")}>
            One Card Layout
          </Button>
          <Button onClick={() => navigate("/")}>Home Page</Button>
        </form>
        <div className="questions-guide">
          <h3>How to Ask Qustion:</h3>
          Asking questions for Tarot cards is an important part of the process,
          as it helps you gain insights and guidance. Here are some tips on how
          to ask questions correctly for Tarot readings:
          <ul className="how-to-ask-questions">
            <li>
              1.
              <span className="important_knowledge">Be Specific:</span>
              Tarot cards work best when you ask specific questions. Vague or
              overly broad questions may yield unclear or confusing answers.
              Instead of asking, "What does the future hold for me?" try
              something like,
              <span className="important_knowledge">
                "What can I expect in my career over the next six months?"
              </span>
            </li>
            <li>
              2.
              <span className="important_knowledge">
                Use Positive Language:
              </span>
              Frame your questions in a positive and empowering manner. Instead
              of asking, "Will I ever get out of this bad situation?" try,
              <span className="important_knowledge">
                "What steps can I take to improve my current situation?"
              </span>
            </li>
            <li>
              3.<span className="important_knowledge">Focus on Yourself:</span>
              Tarot readings are meant to provide guidance for your own life
              journey. Avoid asking questions about other people's lives or
              prying into their personal matters without their consent.
            </li>
            <li>
              4.
              <span className="important_knowledge">
                Avoid Yes/No Questions:
              </span>
              While yes/no questions are possible with Tarot, they often limit
              the depth of the answer. It's better to ask open-ended questions
              that encourage a more detailed response. For example, instead of
              asking, "Will I get the job?" ask,{" "}
              <span className="important_knowledge">
                "What do I need to know about my job prospects?"
              </span>
            </li>
            <li>
              5. Ask About the Present or Future: Tarot cards are generally
              better suited for providing insights about the present and future
              rather than the past.
              <span className="important_knowledge">
                You can ask questions about current challenges, potential
                outcomes, or guidance for upcoming decisions.
              </span>
            </li>
            <li>
              6. Keep Questions Neutral:
              <span className="important_knowledge">
                Avoid leading or biased questions that suggest a particular
                answer.
              </span>
              Keep your questions neutral to allow the cards to provide an
              unbiased response.
            </li>

            <li>
              7. Be Mindful of Your Energy:
              <span className="important_knowledge">
                Before asking a question, take a moment to center yourself and
                focus on your intentions.
              </span>
              Your energy and mindset can influence the reading, so try to
              approach it with a clear and open mind.
            </li>
            <li>
              8.{" "}
              <span className="important_knowledge">
                One Question at a Time:
              </span>{" "}
              Ask one question at a time to ensure clarity and a focused
              response. If you have multiple concerns, consider doing separate
              readings for each question.
            </li>
            <li>
              9.
              <span className="important_knowledge">
                Phrase Questions in the First Person:
              </span>{" "}
              Ask questions as if you are talking to yourself or your higher
              self. For example, instead of saying, "Is he in love with me?"
              say,{" "}
              <span className="important_knowledge">
                "What do I need to know about our relationship?"
              </span>
            </li>
          </ul>
          Remember that Tarot readings are not fortune-telling but a tool for
          self-reflection and guidance. The interpretation of the cards can be
          influenced by the reader's intuition and your own personal connection
          to the symbols and imagery. Ultimately, Tarot is a means to gain
          insight and perspective on your life's journey, so approach it with an
          open heart and an inquisitive mind.
        </div>
      </div>
    </LayoutContainer>
  );
}

export default MyQuestions;
