import React, { useState, useEffect } from "react";
import { animateScroll as scroll } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import Collapsible from "react-collapsible";
import { BsChevronDown } from "react-icons/bs";
import LayoutContainer from "../../../containers/Layout";
import CardsData from "../../../provider/CardsData";
import CardButton from "../../../components/CardButton";
import ButtonGeneral from "../../../components/Button";
import { generateId } from "../../../utils/generateNumber";
import tarot_cards_spin from "../../../images/tarot_cards_spin.gif";
import style from "../Learn/styles.css";
import MetaTags from "../../../components/MetaTags";

export default function LearnCards({ children }) {
  const { data: cards, isLoading } = CardsData();

  const [isCardLoaded, setIsCardLoaded] = useState(false);

  const [cardsAll, setCardsAll] = useState(() => {});
  const navigate = useNavigate();
  const location = useLocation();

  const goToCardDetailsPage = (cardName) => {
    navigate(`${location.pathname}/${cardName}`);
    handleScrollToTop();
  };

  const handleScrollToTop = () => {
    scroll.scrollToTop({ smooth: true });
  };

  const scrollToMiddle = () => {
    const middleOfScreen = window.innerHeight;
    scroll.scrollTo(middleOfScreen, {
      duration: 500, // Optional duration in milliseconds for smooth scrolling
      delay: 0, // Optional delay in milliseconds before scrolling starts
      smooth: true, // Optional boolean flag to enable/disable smooth scrolling
    });
  };

  function setCardsData(suit, cardsData) {
    setCardsAll((prevCards) => ({
      ...prevCards,
      [suit]: {
        cards: cardsData.map((card) => {
          let cardName = card.name.replace(/\s/g, "-");

          return (
            <CardButton
              key={generateId()}
              cardImage={card.img}
              onClick={() => goToCardDetailsPage(cardName.toLowerCase())}
            ></CardButton>
          );
        }),
      },
    }));
  }
  function loadCardsData() {
    const majorArcana = cards.filter((card) => card.suit === "Trump");
    majorArcana.sort((a, b) => Number(a.number) - Number(b.number));
    setCardsData("trump", majorArcana);
    const pentacles = cards.filter((card) => card.suit === "Pentacles");
    setCardsData("pentacles", pentacles);
    const cups = cards.filter((card) => card.suit === "Cups");
    setCardsData("cups", cups);
    const swords = cards.filter((card) => card.suit === "Swords");
    setCardsData("swords", swords);
    const wands = cards.filter((card) => card.suit === "Wands");
    setCardsData("wands", wands);

    if (cards.length > 0) setIsCardLoaded(true);
  }

  useEffect(
    () => {
      if (cards) loadCardsData();
    }, // eslint-disable-next-line
    [cards, isLoading]
  );

  return (
    <LayoutContainer>
      <div className="general-learn">
        <h1>Cards Tarot</h1>
        <p>
          Tarot cards are a deck of 78 cards, typically divided into the Major
          Arcana (22 cards) and the Minor Arcana (56 cards), which are often
          used for divination and spiritual guidance. Each card features a
          unique image and symbolism that can be interpreted in different ways,
          depending on the context and the reader's intuition.
        </p>
        <p>
          Tarot readings are often used as a tool for self-discovery and
          reflection, as well as for gaining insight into specific questions or
          situations. During a reading, the reader shuffles the deck of cards
          and selects a certain number of cards based on the spread being used
          (a specific pattern for laying out the cards).
        </p>
        <p>
          The meaning of each card in a reading depends on its position in the
          spread and its relationship to the other cards in the spread. Tarot
          readers often use their intuition, as well as their knowledge of the
          symbolism and traditional meanings of each card, to interpret the
          reading and provide guidance and insight to the querent (the person
          receiving the reading).
        </p>
        <p>
          Tarot has a rich history that spans several centuries and different
          cultures, and the meanings and interpretations of the cards have
          evolved over time. While some people view tarot as a form of
          fortune-telling, others see it as a tool for self-reflection and
          spiritual growth.
        </p>

        <ButtonGeneral onClick={() => navigate("/learn-cards/quizzes")}>
          Take a Quiz
        </ButtonGeneral>
      </div>

      {!isCardLoaded ? (
        <div>
          <h2>Loading...</h2>
          <img className="cardLoading" src={tarot_cards_spin} alt="cardsSpin" />
        </div>
      ) : (
        <div className="cards-container" css={style}>
          <Collapsible
            trigger={
              <div className="custom-trigger" onClick={scrollToMiddle}>
                <span>Major Arcana</span>
                <BsChevronDown onClick={scrollToMiddle} />
              </div>
            }
          >
            <h3>Major Arcana</h3>
            <div className="cards-container-suite">{cardsAll.trump.cards}</div>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="custom-trigger" onClick={scrollToMiddle}>
                <span>Pentacles</span>
                <BsChevronDown onClick={scrollToMiddle} />
              </div>
            }
          >
            <h3>Pentacles</h3>
            <div>
              <p>
                In tarot, the suit of Pentacles (also known as Coins, Disks, or
                Diamonds) is associated with the element of earth, representing
                the physical realm, material possessions, and practical matters.
                Pentacles symbolize wealth, abundance, and material success, as
                well as the hard work and effort required to achieve it.
              </p>
              <p>
                Each card in the Pentacles suit has its own unique meaning and
                interpretation, but some common themes associated with this suit
                include:
              </p>
              <p>
                Finances and material possessions: Pentacles cards often relate
                to money, investments, and financial stability. They can
                indicate material gain or loss, financial investments, and the
                accumulation of wealth.
              </p>
              <p>
                Hard work and perseverance: Pentacles cards can also represent
                the value of hard work, dedication, and persistence in achieving
                one's goals. They may suggest the need for discipline, focus,
                and attention to detail in order to succeed.
              </p>
              <p>
                Physical health and well-being: Because the suit of Pentacles is
                associated with the element of earth, it can also relate to
                physical health and well-being. Pentacles cards may suggest the
                need to take care of one's body, or they may indicate health
                issues that need to be addressed.
              </p>
              <h3>
                Some of the specific cards in the Pentacles suit and their
                meanings include:
              </h3>
              <ul>
                <li>
                  Ace of Pentacles: A new beginning in the material realm, often
                  related to finances, business, or career opportunities.
                </li>
                <li>
                  Two of Pentacles: Balancing multiple priorities or
                  responsibilities, often related to work, finances, or home
                  life.
                </li>
                <li>
                  Three of Pentacles: Collaboration, teamwork, and
                  skill-building, often related to work or creative projects.
                </li>
                <li>
                  Four of Pentacles: Hoarding or holding onto material
                  possessions, often out of fear or insecurity.
                </li>
                <li>
                  Five of Pentacles: Financial hardship or lack, often related
                  to unexpected expenses or loss of income.
                </li>
                <li>
                  Six of Pentacles: Generosity, charity, and sharing resources
                  with others.
                </li>
                <li>
                  Seven of Pentacles: Patience, perseverance, and waiting for
                  the fruits of one's labor to come to fruition.
                </li>
                <li>
                  Eight of Pentacles: Mastery, skill-building, and
                  craftsmanship, often related to work or creative pursuits.
                </li>
                <li>
                  Nine of Pentacles: Financial independence, luxury, and
                  enjoying the fruits of one's labor.
                </li>
                <li>
                  Ten of Pentacles: Wealth, abundance, and financial security,
                  often related to family, inheritance, or legacy.
                </li>
              </ul>
            </div>
            <div className="cards-container-suite">
              {cardsAll.pentacles.cards}
            </div>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="custom-trigger" onClick={scrollToMiddle}>
                <span>Cups</span>
                <BsChevronDown onClick={scrollToMiddle} />
              </div>
            }
          >
            <h3>Cups</h3>
            <div className="cards-container-suite">{cardsAll.cups.cards}</div>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="custom-trigger" onClick={scrollToMiddle}>
                <span>Swords</span>
                <BsChevronDown onClick={scrollToMiddle} />
              </div>
            }
          >
            <h3>Swords</h3>
            <div className="cards-container-suite">{cardsAll.swords.cards}</div>
          </Collapsible>
          <Collapsible
            trigger={
              <div className="custom-trigger" onClick={scrollToMiddle}>
                <span>Wands</span>
                <BsChevronDown onClick={scrollToMiddle} />
              </div>
            }
          >
            <h3>Wands</h3>
            <div className="cards-container-suite">{cardsAll.wands.cards}</div>
          </Collapsible>
          <MetaTags
            description={
              "Unlock the mysteries of Tarot card meanings. Explore in-depth insights into every card's symbolism, interpretations, and more. Your guide to mastering the art of Tarot card reading awaits."
            }
          />
        </div>
      )}
    </LayoutContainer>
  );
}
