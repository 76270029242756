import React, { useEffect, useCallback, useState } from "react";
import firebaseApp from "../config/FirebaseConfiguration.js";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore/lite";

export const CardsContext = React.createContext(null);

function CardContextProvider({ children }) {
  const db = getFirestore(firebaseApp);

  const [cards, setCards] = useState([]);
  const [keyWords, setKeyWords] = useState([]);
  const [cardsName, setCardsName] = useState([]);

  const loadAllCards = useCallback(async () => {
    const cardsCollection = collection(db, "cards");
    const querySnapshot = await getDocs(cardsCollection);
    let arrayOfCards = [];
    querySnapshot.forEach((doc) => {
      arrayOfCards.push(doc.data());
    });
    setCards(arrayOfCards);
  }, [db]);

  const loadAllKeywords = useCallback(async () => {
    const docRef = doc(db, "search", "keywords");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setKeyWords(docSnap.data().keywords);
    } else {
      console.log("No such document!");
    }
  }, [db]);

  const loadCardsName = useCallback(async () => {
    const docRef = doc(db, "search", "cards-name");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setCardsName(docSnap.data().cards_name);
    } else {
      console.log("No such document!");
    }
  }, [db]);

  useEffect(() => {
    loadAllCards();
    loadAllKeywords();
    loadCardsName();
  }, [loadAllCards, loadAllKeywords, loadCardsName]);

  const cardsValues = {
    cards,
    keyWords,
    cardsName,
  };

  return (
    <CardsContext.Provider value={cardsValues}>
      {children}
    </CardsContext.Provider>
  );
}

export default CardContextProvider;
