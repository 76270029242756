import React, { useEffect, useState } from "react";
import { generateId } from "../../../utils/generateNumber";
import styles from "./styles.css";
import { useGetChatGPTResponse } from "../../../provider/OpenAIResponse";
import { useQueryClient } from "@tanstack/react-query";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../config/FirebaseConfiguration";
import { RWebShare } from "react-web-share";

const PersonalCardInfo = (props) => {
  const [chatGPResponseState, setChatGPResponseState] = useState(
    "⏳ Wait for the response from AI/ChatGPT - it will take a few seconds"
  );
  const {
    data: chatGPResponse,
    isLoading,
    isFetching
  } = useGetChatGPTResponse(
    props.chosenCard.name,
    props.userQuestion,
    props.isReversed
  );
  const queryClient = useQueryClient();

  useEffect(
    () => {
      if (isFetching) setChatGPResponseState("⏳ Wait for the response from AI/ChatGPT - it will take a few seconds");
      if (chatGPResponse && !isLoading && !isFetching) {
        setChatGPResponseState(chatGPResponse);
        let userQuestion = queryClient.getQueryData(["userQuestion"]);
        userQuestion.answers.push(chatGPResponse);
        queryClient.setQueryData(["userQuestion"], userQuestion);
      }
    }, // eslint-disable-next-line
    [isLoading, chatGPResponse]
  );

  return (
    <div className="one-card-reveal">
      <h2>{props.chosenCard.name}</h2>
      {props.chosenCard.arcana === "Minor Arcana" && (
        <div className="meaning-affirmation">
          {props.chosenCard.affirmation}
        </div>
      )}
      <div className="one-card-display" css={styles}>
        <img
          className={
            props.isReversed ? "card-image card-image-reversed" : "card-image"
          }
          src={props.chosenCard.img}
          alt="chosen card"
        />
        <div className="meanings-of-card" css={styles}>
          <div className="meaning meaning-wide">
            {props.isReversed ? (
              <div className="personal-meaning">
                <ul>
                  <li>{chatGPResponseState}</li>
                  <li>
                    {
                      props.chosenCard.personal_reversed_meaning[
                        props.personalMeaning
                      ]
                    }
                  </li>
                </ul>
              </div>
            ) : (
              <div className="personal-meaning">
                <ul>
                  <li>{chatGPResponseState}</li>
                  <li>
                    {props.chosenCard.personal_meaning[props.personalMeaning]}
                  </li>
                  {props.chosenCard.fortune_telling.map((keyword) => (
                    <li key={generateId()}>{keyword}</li>
                  ))}
                </ul>
              </div>
            )}
            <div className="meaning meaning-title">
              Questions to Ask Yourself:
              <ul>
                {props.chosenCard.questions_to_ask.map((keyword) => (
                  <li key={generateId()}>{keyword}</li>
                ))}
              </ul>
            </div>
            {props.isReversed ? (
              <div className="meaning meaning-title">
                Shadow Meaning:
                <ul>
                  {props.chosenCard.meanings.shadow.map((keyword) => (
                    <li key={generateId()}>{keyword}</li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="meaning-title">
                General Meaning:
                <ul>
                  {props.chosenCard.meanings.light.map((keyword) => (
                    <li key={generateId()}>{keyword}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <RWebShare
        data={{
          text: `Hey there, I want to tell you about my time using the Tarot 
          Diary website. I asked a question: "${props.userQuestion}" and got this answer: "${chatGPResponse}". You can give it a shot too, ask anything you want. Just click on this link to check out the website: `,
          url: "https://diarytarot.com",
          title: "Tarot Diary",
        }}
        onClick={() => logEvent(analytics, "click_share_button", "button")}
      >
        <button className="button-share">Send the Answer 💬 </button>
      </RWebShare>
    </div>
  );
};

export default PersonalCardInfo;
