import { useState, useMemo } from "react";
import MetaTags from "../../components/MetaTags";
import { logEvent } from "firebase/analytics";
import LayoutContainer from "../../containers/Layout";
import { useGetQuestions } from "../../provider/QuestionsData";
import { useGetPersonalizedCards } from "../../provider/CardsPersonalized";
import StatisticsDisplay from "./components/StatisticDisplay";
import { useAuthContext } from "../../provider/Login/AuthProvider";
import { analytics } from "../../config/FirebaseConfiguration";
import { useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import styles from "./styles.css";
import dayjs from "dayjs";
import {
  currentYear,
  currentMonth,
  listOfMonths,
  updateStatistics,
  findRepeatedValues,
} from "./utils";
import ButtonGeneral from "../../components/Button";
import CardsData from "../../provider/CardsData";
export default function MyDiary() {
  const userData = useAuthContext();

  const { data: allQuestions } = useGetQuestions(
    userData ? userData.uid : "no_user"
  );
  const { data: personalizedCards } = useGetPersonalizedCards();
  const [showStats, setToShowStatistics] = useState(false);
  const [questionsDates, setQuestionsDates] = useState(() => []);
  const [numberOfCardsInYear, setNumberOfCardsInYear] = useState(0);
  const [personalizedCardsUpdated, setPersonalizedCardsUpdated] = useState([]);
  const { data: cards } = CardsData();
  const [updateStatsData, setUpdateStatData] = useState(() => ({
    numberOfCardsInMonth: 0,
    numberOfMajorArcana: 0,
    numberOfCups: 0,
    numberOfPentacles: 0,
    numberOfWands: 0,
    numberOfSwords: 0,
  }));

  const location = useLocation();

  const buildQuestionDate = () => {
    const sortedQuestions = allQuestions
      .sort((a, b) => {
        if (isNaN(b.dateTime) || isNaN(a.dateTime)) {
          // Handle invalid or missing date values here
          return 0; // For example, consider them equal
        }
        return b.dateTime - a.dateTime; // Sort in ascending orders
      })
      .slice(0, 5);
    if (allQuestions) {
      setQuestionsDates(
        sortedQuestions.map((questionCard) => {
          const date = dayjs(questionCard.date);
          const dateToDisplay = date.format("MMM D");
          return (
            <li style={{ marginBottom: "10px" }} key={questionCard.dateTime}>
              <span style={{ marginRight: "10px" }}>{dateToDisplay}</span>
              <Link to={`${location.pathname}/${questionCard.dateTime}`}>
                {questionCard.question}
              </Link>
            </li>
          );
        })
      );
    }
  };

  const buildCardsData = () => {
    logEvent(analytics, "click_reveal_mood", "button");
    let arrayOfCards = allQuestions.map((question) => question.cards[0]);
    let repeated = findRepeatedValues(arrayOfCards);
    repeated.sort((a, b) => Number(b.cardsCount) - Number(a.cardsCount));
    const cardsWithInter = repeated.map((card) => {
      let filtered = [];
      filtered = personalizedCards.cards.filter(
        (perCard) => perCard.card === card.cardName
      );
      return {
        ...card,
        interpretation: filtered[0].interpretation,
      };
    });

    const cardsWithImages = cardsWithInter.map((card) => {
      const filtered = cards.map((perCard) => {
        if (perCard.name === card.cardName) {
          return {
            ...card,
            cardImage: perCard.img,
          };
        } else {
          return {
            ...card,
          };
        }
      });

      // Filter to get only objects with 'cardImage' property
      const filteredPArray = filtered.filter((card) =>
        card.hasOwnProperty("cardImage")
      );

      // Return the first element in the filtered array or an empty object
      return filteredPArray.length > 0 ? filteredPArray[0] : {};
    });

    setPersonalizedCardsUpdated(cardsWithImages);
    setToShowStatistics((prev) => !prev);
  };

  const statsToDisplay = useMemo(() => {
    return (
      <StatisticsDisplay
        key={`${currentYear}-${currentMonth}`}
        selectedYear={{
          value: currentYear,
          label: currentYear,
        }}
        selectedMonth={{
          value: currentMonth,
          label: listOfMonths[currentMonth - 1],
        }}
        yearlyStatistics={numberOfCardsInYear}
        stats={updateStatsData}
        isYearlyOnly={true}
        personalizedCards={personalizedCardsUpdated}
      />
    );
  }, [numberOfCardsInYear, updateStatsData, personalizedCardsUpdated]);

  useEffect(() => {
    // You can also access the latest values of the props here
    if (allQuestions) {
      let dataOfCardsInYear = allQuestions.filter(
        (questionCard) => questionCard.year === currentYear
      );
      //setYearlyQuestionsData(dataOfCardsInYear);
      setNumberOfCardsInYear(dataOfCardsInYear.length);
      buildQuestionDate();
      //updateStatisticsForYear();
      const updatedStatics = updateStatistics(
        dataOfCardsInYear,
        currentYear,
        currentMonth,
        true
      );
      setUpdateStatData(updatedStatics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, allQuestions]);

  return (
    <LayoutContainer>
      <h1>My Diary</h1>
      <div className="guide">
        <h2>Discover Your Focus</h2>
        <p>
          When you get random Tarot cards, they reflect the energies at play in
          your life. Whether it's matters of the heart with Cups, practical
          matters with Pentacles, mental challenges with Swords, or creative
          endeavors with Wands, each suit has a story to tell. Your card draws
          can provide guidance and self-reflection, offering a glimpse into the
          themes and energies that may shape your upcoming month or year.
        </p>
        <h2>Embrace the Wisdom</h2>
        <p>
          As you explore the world of Tarot on our website, remember that these
          cards are a reflection of your inner self and the world around you.
          Embrace the insights they offer, and use them as a compass to navigate
          the beautiful, intricate landscape of your life. Whether it's matters
          of the heart, financial decisions, mental challenges, or creative
          pursuits, Tarot is here to empower you on your journey. Enjoy your
          Tarot readings, and may they guide you toward a future filled with
          self-discovery and understanding.
        </p>
      </div>
      {!showStats ? (
        <ButtonGeneral onClick={() => buildCardsData(() => {})}>
          See your mood
        </ButtonGeneral>
      ) : (
        <ButtonGeneral onClick={() => setToShowStatistics((prev) => !prev)}>
          Hide your mood
        </ButtonGeneral>
      )}
      {showStats && <div>{statsToDisplay}</div>}
      <Link to={"/statistics"}>
        Click here to reveal it for each month
      </Link>{" "}
      <h2>Your Previously Asked Questions</h2>
      <div className="questions-cards-container" css={styles}>
        {userData && questionsDates}
      </div>
      <Link to={"/all-asked-questions"}>
        Click on this link to see all you saved questions
      </Link>
      <MetaTags
        description={
          "Keep a comprehensive Tarot journal diary. Track card suits, repeated cards, and store your insights. Explore the patterns in your readings and unlock the wisdom of your Tarot journey."
        }
      />
    </LayoutContainer>
  );
}
