import {
  GoogleAuthProvider,signInWithPopup, FacebookAuthProvider
  } from "firebase/auth";
  import { auth } from "../../config/FirebaseConfiguration.js";

export  const  handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth,provider);
  };


  export  const  handleSignInWithFacebook = async () => {
    const provider = new FacebookAuthProvider();
    await signInWithPopup(auth,provider);
  };