import { AuthErrorCodes } from "firebase/auth";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LayoutContainer from "../../../containers/Layout";
import { signIn } from "../../../provider/Login/LoginEmailPassword";
import ButtonGeneral from "../../../components/Button";
import { useQueryClient } from "@tanstack/react-query";
import styles from "../styles.css"

const Registered = () => {
  const queryClient = useQueryClient();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const userData = queryClient.getQueryData(["userData"]);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const handleSignIn = async () => {
    if (email === "" || password === "") {
      setError("Please fill in all fields");
    } else {
      try {
        const userObj = await signIn(email, password);

        const userData = userObj.user;

        queryClient.setQueryData(["userData"], userData);
        let userQuestion = queryClient.getQueryData(["userQuestion"]);
        if (userQuestion) {
          navigate("/saved-card");
        } else navigate("/reading-one-card");
      } catch (error) {
        if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
          setError("The email exists, try to login");
        }
        if (error.code === AuthErrorCodes.INVALID_OAUTH_CLIENT_ID) {
          setError("The email exists, try to signIn");
        }
        if (error.code === AuthErrorCodes.INVALID_EMAIL) {
          setError("The email is invalid");
        }
        if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
          setError("The password is invalid");
        }
        if (error.code === AuthErrorCodes.WEAK_PASSWORD) {
          setError("Password should be at least 6 characters");
        }
      }
    }
  };

  const handleEmailChange = (e) => {
    const enteredEmail = e.target.value;
    setEmail(enteredEmail);
    setEmailValid(validateEmail(enteredEmail));
  };
  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    // Check if passwords match and set the state accordingly
    setPasswordMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    // Check if passwords match and set the state accordingly
    setPasswordMatch(e.target.value === password);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <LayoutContainer>
      <h1>Registered a New Magic Account</h1>
      <div className="registered_container" css={styles}>
        <div className="guide_text_auth">
          <p>
            You can create an account! <br />
            Type your email and password with at least 6 characters and then
            click on "Click Your Account" button.
          </p>
        </div>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {!userData && (
          <form className="form-to-register">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              required
            />
            {!emailValid && (
              <p style={{ color: "red" }}>Invalid email address</p>
            )}
            <label htmlFor="password">Password:</label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
            />
            <label htmlFor="confirm_password">Confirm Password:</label>
            <input
              type={showPassword ? "text" : "password"}
              id="confirm_password"
              name="confirm_password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              required
            />
            {!passwordMatch && (
              <p style={{ color: "red" }}>Passwords do not match</p>
            )}
            <div className="checkbox-container">
              <label htmlFor="showPasswordCheckbox">
                {showPassword ? "Hide Password" : "Show Password"}{" "}
              </label>
              <input
                type="checkbox"
                id="showPasswordCheckbox"
                onChange={togglePasswordVisibility}
              />
            </div>
            <button
              type="button"
              className="auth_button"
              onClick={handleSignIn}
            >
              Create Your Account
            </button>
          </form>
        )}
        <ButtonGeneral onClick={() => navigate("/login")}>
          Go to Login
        </ButtonGeneral>
      </div>
    </LayoutContainer>
  );
};

export default Registered;
