import { useContext, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CardsContext } from "../../../provider/CardsContext";
import LayoutContainer from "../../../containers/Layout";
import FullCardInfo from "../../../containers/CardInfoContainer/FullCardInfo";
import Button from "../../../components/Button";

export default function CardPage() {
  const { cards } = useContext(CardsContext);
  const { cardName } = useParams();

  const navigate = useNavigate();

  const cardToDisplay = useMemo(() => {
    return cards.filter(
      (card) => card.name.replace(/\s/g, "-").toLowerCase() === cardName
    );
  }, [cards, cardName]);

  return (
    <LayoutContainer>
      <Button onClick={() => navigate("/learn-cards")}>
        Go Back to All Cards
      </Button>{" "}
      <FullCardInfo chosenCard={cardToDisplay[0]} />
    </LayoutContainer>
  );
}
