import React, { useState } from "react";
import { logEvent } from "firebase/analytics";
import { useQueryClient } from "@tanstack/react-query";
import { analytics } from "../../../config/FirebaseConfiguration";
import LayoutContainer from "../../../containers/Layout";
import { generateNumber, ReversedOrNot } from "../../../utils/generateNumber";
import Button from "../../../components/Button";
import PersonalCardInfo from "../../../containers/CardInfoContainer/PersonalCardInfo";
import CardsData from "../../../provider/CardsData";
import { useAuthContext } from "../../../provider/Login/AuthProvider";
import MetaTags from "../../../components/MetaTags";
import { newQuestionForm } from "../../Questions/QuestionsUtils";
import TarotCard from "../../../components/TarotCard/TarotCard";
import styles from "./styles.css";

export default function YesNoLayout({ children }) {
  /**
   * States related to cards
   */
  const [cardLoaded, setCardLoaded] = useState(false);
  const [beforeCardLoaded, setBeforeCardLoaded] = useState(true);
  const [chosenCard, setChosenCard] = useState({});
  const [isReversed, setIsReversed] = useState(false);
  const [userQuestion, setUserQuestion] = useState(
    () =>
      `Yes, no or maybe? Please answer Yes or No or Maybe first before giving interpretation.`
  );
  const userData = useAuthContext();
  const queryClient = useQueryClient();

  const { data: cards } = CardsData();

  function loadCardData() {
    const randomCard = generateNumber();
    let cardChosen = cards[randomCard];
    setChosenCard(() => cardChosen);
  }

  function cardClicked() {
    if (cards) {
      setBeforeCardLoaded(false);
      setCardLoaded(true);
      let newQuestion = newQuestionForm(
        userData,
        `Yes, no or maybe? Please answer Yes or No or Maybe first before giving interpretation.`
      );
      setUserQuestion(
        `Yes, no or maybe? Please answer Yes or No or Maybe first before giving interpretation.`
      );
      queryClient.setQueryData(["userQuestion"], newQuestion);
      loadCardData();
      setIsReversed(ReversedOrNot()); //50% probability of getting true

      logEvent(analytics, "click_one_card", "button");
    }
  }

  function hideCard() {
    setCardLoaded(false);
    setBeforeCardLoaded(true);
    logEvent(analytics, "click_once_again", "button");
  }

  /**
   * Shuffling
   */
  const [isShuffling, setIsShuffling] = useState(false);

  const shuffleCard = () => {
    logEvent(analytics, "click_shuffle", "button");
    setIsShuffling(true);
    setTimeout(() => {
      setIsShuffling(false);
    }, 1000); // Adjust the duration to match your animation
  };

  return (
    <LayoutContainer>
      <h1>Get Answer with a Yes, No or Maybe</h1>
      {beforeCardLoaded && (
        <div className="one-card-spread-guide">
          <div className="guide-to-click">
            <div className="guide-to-click-the-card">
              Focus Your Question: Begin by focusing your mind on a specific
              Yes,No or Maybe question. Whether it's a question of the heart, career
              choices, or personal decisions, clarity in your question brings
              clarity in the cards.
            </div>
            <div className="shuffle-instructions" css={styles}>Before you Start click on Shuffle</div>
            <Button onClick={shuffleCard}>Shuffle</Button>
            <div>Choose one of the cards below</div>
            <div className="cards-shuffling-container">
              <TarotCard
                cardIndex="1"
                isShuffling={isShuffling}
                cardClicked={cardClicked}
              />
              <TarotCard
                cardIndex="2"
                isShuffling={isShuffling}
                cardClicked={cardClicked}
              />
              <TarotCard
                cardIndex="3"
                isShuffling={isShuffling}
                cardClicked={cardClicked}
              />
              <TarotCard
                cardIndex="4"
                isShuffling={isShuffling}
                cardClicked={cardClicked}
              />
              <TarotCard
                cardIndex="5"
                isShuffling={isShuffling}
                cardClicked={cardClicked}
              />
              <TarotCard
                cardIndex="6"
                isShuffling={isShuffling}
                cardClicked={cardClicked}
              />
            </div>
          </div>
        </div>
      )}

      {cardLoaded && (
        <PersonalCardInfo
          chosenCard={chosenCard}
          personalMeaning={0}
          isReversed={isReversed}
          userQuestion={userQuestion}
        />
      )}

      {cardLoaded && (
        <div className="mysticsense_recomendation">
          You can find more meaning in your interpretation by referring to the
          master in Tarot reading.
          <br /> Here, you get a
          <a href="https://www.mysticsense.com/chat-psychics/#a_aid=diarytarotworld&a_bid=206c45d4">
            {" "}
            5 Minutes Free - Online Chat with Real Person
          </a>
        </div>
      )}
      {cardLoaded && (
        <div className="one-card-spread-guide">
          <p className="one-card-guide">
            🙏 Supporting Our Journey: By choosing to tip, you're not just
            giving back; you're actively supporting our mission to keep Tarot
            accessible to everyone. Your generosity enables us to maintain and
            enhance this service.
          </p>
          <a
            href="https://ko-fi.com/R5R7P2LKO"
            target="_blank"
            rel="noreferrer"
          >
            <img
              height="36"
              style={{ border: "0px", height: "50px" }}
              src="https://storage.ko-fi.com/cdn/kofi3.png?v=3"
              border="0"
              alt="Buy Me a Coffee at ko-fi.com"
            />
          </a>
        </div>
      )}
      {cardLoaded && (
        <div className="buttons_display">
          <p>
            You can save your answer and read it later. By saving the answer it
            will allow you to see what cards are repeated each time when you ask
            a question. It also gives an opportunity to analyze your energies
            for the year and for the month.
          </p>
          <Button onClick={hideCard}>Try Once Again</Button>
        </div>
      )}
      <MetaTags
        description={
          "Get personalized Tarot insights with AI ChatGPT. Ask any question, and receive expert Tarot interpretations instantly. Uncover answers to life's mysteries through the power of Tarot and AI."
        }
      />
    </LayoutContainer>
  );
}
