import React from "react";
import styles from "./styles.css";
import cardBack from "../../images/cardBack.jpeg";

export default function CardButton({ onClick, cardImage }) {
  return (
    <div onClick={onClick}>
      <img
        className="card-button"
        onClick={onClick}
        src={!cardImage ? cardBack : cardImage}
        alt="card-draw"
        css={styles}
      />
    </div>
  );
}
