import React from "react";
import styles from "./styles.css";

export default function ButtonGeneral({
  disabled,
  onClick,
  children,
  className,
}) {
  return (
    <button
      className={className ? className : "button"}
      disabled={disabled}
      onClick={onClick}
      css={styles}
    >
      {children}
    </button>
  );
}
