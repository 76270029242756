import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import LayoutContainer from "../../../containers/Layout";
import QuestionCardsInfo from "../../../containers/QuestionCardInfo";
import Button from "../../../components/Button";

const AnswerCards = () => {
  const { dateTime } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const allQuestions = queryClient.getQueryData(["allQuestions"]);
  const cardToDisplay = useMemo(() => {
    const filteredQuestions = allQuestions.filter(
      (questionDate) => questionDate.dateTime === dateTime
    );
    return filteredQuestions[0];
  }, [dateTime, allQuestions]);

  return (
    <LayoutContainer>
      <QuestionCardsInfo cardToDisplay={cardToDisplay} />
      <Button onClick={() => navigate("/my-tarot-diary")}>
        Go Back to My Diary
      </Button>{" "}
    </LayoutContainer>
  );
};

export default AnswerCards;
