import cups from "../../../images/Cup.png";
import pentacles from "../../../images/Pentacles.png";
import wands from "../../../images/WandsImg.png";
import swords from "../../../images/Swords.png";
import majorarcana from "../../../images/MajorArcana.png";


export default function StatisticsInterpretation({
  numberOfMajorArcana,
  numberOfCups,
  numberOfPentacles,
  numberOfWands,
  numberOfSwords,
}) {

  return (
    <div>
      <div>
        <h2> Cups: {numberOfCups}</h2>
        <p className="statistic-text">
          <img className="statistic-image" src={cups} alt="navbar-icon" />
          In tarot reading, if you receive a lot of Cups cards in your spreads,
          it often indicates a{" "}
          <span className="important_knowledge">
            {" "}
            strong emphasis on emotions , relationships, and matters of the
            heart.
          </span>{" "}
          You got {numberOfCups} cards with the Cups. Cups are associated with
          the element of water and represent the realm of feelings, love,
          intuition, and the subconscious mind. When you receive multiple Cups
          cards in a reading, it suggests several possible interpretations and
          themes:
        </p>
        <ul>
          {" "}
          <li>
            <span className="important_knowledge">1. Emotional Focus: </span>{" "}
            Cups cards indicate that your current situation or question is
            closely tied to your emotions. It could represent a time of
            heightened sensitivity or a need to pay attention to your feelings.
          </li>{" "}
          <li>
            <span className="important_knowledge">
              {" "}
              2. Relationship Matters:
            </span>{" "}
            Cups are strongly associated with relationships, including romantic
            relationships, friendships, and family connections. The presence of
            Cups cards may suggest a need for emotional exploration and
            connection in these areas.
          </li>{" "}
          <li>
            <span className="important_knowledge"> 3. Self-Reflection:</span>{" "}
            This could be a time for introspection and self-discovery. You may
            be grappling with your inner world, past experiences, or personal
            growth.{" "}
          </li>
          <li>
            <span className="important_knowledge">
              4. Intuition and Psychic Awareness:{" "}
            </span>{" "}
            The Cups also relate to intuition and psychic abilities. If you
            receive multiple Cups cards, it may be a sign that you should pay
            more attention to your intuitive insights or seek spiritual and
            emotional guidance.
          </li>
          <li>
            <span className="important_knowledge">
              {" "}
              5. Healing and Emotional Well-Being:{" "}
            </span>{" "}
            The Cups can indicate a need for healing or emotional support. You
            may be going through a period of emotional turmoil and may need to
            address and resolve underlying issues.
          </li>
          <li>
            <span className="important_knowledge">
              {" "}
              6. Creativity and Artistic Expression:
            </span>{" "}
            Cups cards can also represent artistic and creative endeavors. You
            may be encouraged to explore your artistic talents and express
            yourself through creative projects.
          </li>{" "}
          <li>
            <span className="important_knowledge">
              7. Nurturing and Self-Care:{" "}
            </span>{" "}
            The Cups cards can be a reminder to take care of your emotional
            well-being. Encouraging self-care practices and nourishing your soul
            may be necessary.
          </li>
        </ul>
      </div>
      <div>
        {" "}
        <h2> Pentacles: {numberOfPentacles}</h2>
        <p>
          <img className="statistic-image" src={pentacles} alt="navbar-icon" />
          When you receive a lot of Pentacles cards in your tarot reading
          spreads, it often signifies
          <span className="important_knowledge">
            {" "}
            a strong focus on material and practical matters.
          </span>
          You got {numberOfPentacles} of cards with Pentacles. Pentacles are
          associated with the element of earth and represent the tangible
          aspects of life, such as finances, health, career, and physical
          well-being. If you find multiple Pentacles cards in your reading, here
          are some possible interpretations and themes:
        </p>
        <ul>
          <li>
            <span className="important_knowledge">1. Financial Matters: </span>{" "}
            Pentacles are strongly linked to money and financial stability.
            Multiple Pentacles cards could suggest that your current situation
            or question pertains to financial issues, such as budgeting,
            investments, or career opportunities.
          </li>
          <li>
            <span className="important_knowledge">2. Material Security:</span>{" "}
            These cards often indicate a concern for your material well-being.
            You might be focused on providing for yourself and your loved ones,
            or you could be contemplating financial security.
          </li>
          <li>
            <span className="important_knowledge">3. Practical Concerns: </span>{" "}
            Pentacles represent practicality and the physical world. This might
            be a time when you're paying attention to everyday responsibilities,
            chores, and day-to-day tasks.
          </li>
          <li>
            <span className="important_knowledge">4. Health and Wellness:</span>{" "}
            Pentacles also touch on physical health and well-being. If you
            receive several Pentacles cards, it could signify a need to
            prioritize your health and fitness or to address any medical
            concerns.
          </li>
          <li>
            <span className="important_knowledge">5. Work and Career: </span>{" "}
            Your career and professional life might be in the spotlight. These
            cards can suggest a time of career growth, stability, or the need to
            make important decisions regarding your job.
          </li>
          <li>
            <span className="important_knowledge">6. Material Goals: </span> You
            may have specific material goals you're striving to achieve. Whether
            it's related to homeownership, financial independence, or acquiring
            assets, these cards suggest that your ambitions are material in
            nature.
          </li>
          <li>
            <span className="important_knowledge">
              7. Groundedness and Practical Wisdom:
            </span>{" "}
            The Pentacles cards also symbolize practical wisdom and being
            grounded. You may need to rely on your practical instincts and
            common sense to navigate a situation.
          </li>
        </ul>
      </div>
      <div>
        {" "}
        <h2>Major Arcana: {numberOfMajorArcana}</h2>
        <img className="statistic-image" src={majorarcana} alt="wands" />
        <ul>
          When you receive a lot of Major Arcana cards in your tarot spread, it
          often indicates
          <span className="important_knowledge">
            {" "}
            a strong emphasis on significant life events, major life lessons,
            and profound spiritual or personal experiences.
          </span>
          The Major Arcana cards are distinct from the Minor Arcana, as they
          represent more profound and life-altering influences. If you find
          multiple Major Arcana cards in your reading, here are some possible
          interpretations and themes:
          <li>
            <span className="important_knowledge">Life Transformations:</span>{" "}
            Major Arcana cards often signify major life changes and
            transformations. They may suggest that you are in the midst of or
            about to go through a significant life-altering event.
          </li>
          <li>
            <span className="important_knowledge">
              {" "}
              Spiritual and Personal Growth:
            </span>{" "}
            These cards are deeply connected to personal and spiritual
            development. Multiple Major Arcana cards could indicate a period of
            profound self-discovery and inner growth.
          </li>
          <li>
            <span className="important_knowledge"> Fateful Events: </span>The
            Major Arcana cards are often seen as cards of destiny. If you
            receive several of them, it may imply that important events or
            turning points are unfolding in your life.
          </li>
          <li>
            <span className="important_knowledge"> Major Life Lessons:</span>{" "}
            Each Major Arcana card carries a specific life lesson or message.
            Receiving many of these cards could imply that you are currently
            learning or need to learn these profound lessons.
          </li>
          <li>
            <span className="important_knowledge">
              {" "}
              Personal Power and Transformation:
            </span>{" "}
            These cards also represent personal power and the ability to
            transform your life. They may suggest that you have the power to
            change your circumstances and achieve personal growth.
          </li>
          <li>
            <span className="important_knowledge"> Spiritual Awakening: </span>
            Multiple Major Arcana cards can be a sign of a spiritual awakening
            or a deeper connection to the spiritual realm. You might be on a
            journey of self-discovery and enlightenment.
          </li>
          <li>
            <span className="important_knowledge">
              {" "}
              Guidance from Higher Forces:{" "}
            </span>
            The Major Arcana cards are often associated with divine or higher
            guidance. If you receive many of them, it could signify that you are
            receiving spiritual guidance and that you should pay attention to
            your inner wisdom.
          </li>
        </ul>
      </div>
      <div>
        <h2>Wands: {numberOfWands}</h2>
        <img className="statistic-image" src={wands} alt="wands" />
        <ul>
          When you receive a lot of Wands cards in your tarot reading spreads,
          it often signifies{" "}
          <span className="important_knowledge">
            {" "}
            a strong emphasis on action, ambition, and creative energy.
          </span>{" "}
          Wands are associated with the element of fire and represent the realm
          of inspiration, passion, and personal growth. If you find multiple
          Wands cards in your reading, here are some possible interpretations
          and themes:
          <li>
            <span className="important_knowledge">Ambition and Drive:</span>{" "}
            Wands cards often highlight your ambition and desire to achieve your
            goals. They suggest a strong focus on your dreams and aspirations.
          </li>
          <li>
            <span className="important_knowledge">Creative Energy: </span>
            These cards are closely linked to creative energy and the spark of
            inspiration. Receiving many Wands cards could indicate a period of
            heightened creativity and a need to express yourself through art,
            projects, or other creative endeavors.
          </li>
          <li>
            <span className="important_knowledge">Action and Initiative: </span>
            Wands signify a call to action. You might be prompted to take the
            initiative, make changes, or pursue your goals with determination
            and enthusiasm.
          </li>
          <li>
            <span className="important_knowledge">
              Passion and Enthusiasm:{" "}
            </span>
            A multitude of Wands cards may symbolize a surge in your passion and
            enthusiasm for a particular project or aspect of your life. It's a
            reminder to harness that energy for positive outcomes.
          </li>
          <li>
            <span className="important_knowledge">
              Leadership and Authority:{" "}
            </span>
            These cards are often associated with leadership and taking charge.
            You may need to step into a leadership role or assert your authority
            in a situation.
          </li>
          <li>
            <span className="important_knowledge">Spiritual Growth:</span> Wands
            also relate to personal growth and spirituality. Multiple Wands
            cards could indicate a period of inner transformation and spiritual
            exploration.
          </li>
          <li>
            <span className="important_knowledge">Challenges and Tests:</span>{" "}
            While Wands are associated with creativity and passion, they can
            also represent challenges and obstacles. If you receive many Wands
            cards, it may be a reminder that you'll face tests and hurdles that
            require determination and perseverance.
          </li>
        </ul>
      </div>
      <div>
        {" "}
        <h2>Swords: {numberOfSwords}</h2>
        <img className="statistic-image" src={swords} alt="swords" />{" "}
        <ul>
          When you receive a lot of Swords cards in your tarot reading spreads,
          it typically indicates{" "}
          <span className="important_knowledge">
            {" "}
            a strong focus on thoughts, mental processes, and the challenges or
            conflicts you may be facing.
          </span>{" "}
          Swords are associated with the element of air and represent the realm
          of the mind, intellect, and communication. If you find multiple Swords
          cards in your reading, here are some possible interpretations and
          themes:
          <li>
            <span className="important_knowledge">Intellectual Focus:</span>{" "}
            Swords cards often point to an intense intellectual focus. You might
            be grappling with complex thoughts, analyzing situations, or dealing
            with matters that require careful consideration.
          </li>
          <li>
            <span className="important_knowledge">
              Conflict and Challenges:
            </span>{" "}
            The presence of Swords cards may suggest that you're currently
            facing challenges, conflicts, or difficult decisions. These cards
            can represent the struggles you're experiencing in your life.
          </li>
          <li>
            <span className="important_knowledge">Communication:</span> Swords
            also relate to communication and the power of words. Multiple Swords
            cards could indicate a need to express yourself clearly, resolve
            conflicts through communication, or be cautious with your words.
          </li>
          <li>
            <span className="important_knowledge">Mental Clarity:</span> These
            cards can symbolize a need for mental clarity and objectivity. You
            may be encouraged to detach emotionally and look at situations with
            a clear, rational mind.
          </li>
          <li>
            <span className="important_knowledge">
              Change and Transformation:
            </span>{" "}
            Swords are often associated with change and transformation. Multiple
            Swords cards might suggest that you're going through a period of
            significant change or that you need to embrace change in your life.
          </li>
          <li>
            <span className="important_knowledge">Conflict Resolution:</span>{" "}
            You may be in a phase where resolving conflicts or making tough
            decisions is essential. These cards can be seen as a call to
            confront and address challenging situations directly.
          </li>
          <li>
            <span className="important_knowledge">
              Mental Strength and Resilience:
            </span>{" "}
            Swords also symbolize mental strength and resilience. You might need
            to tap into your inner strength to overcome obstacles or adversity.
          </li>
        </ul>
      </div>
    </div>
  );
}
