import "./App.css";
import RouterPath from "./router";
import CardContextProvider from "./provider/CardsContext";
import { AuthProvider } from "./provider/Login/AuthProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HelmetProvider } from "react-helmet-async";

const queryClient = new QueryClient();

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <CardContextProvider>
            <RouterPath />
          </CardContextProvider>
          <ReactQueryDevtools initialIsOpen />
        </AuthProvider>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;
