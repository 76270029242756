import dayjs from "dayjs";

export const years = [
  { value: 2023, label: "2023" },
  { value: 2024, label: "2024" },
];

export const months = [
  { value: 1, label: "January" },
  { value: 2, label: "February month" },
  { value: 3, label: "month of March" },
  { value: 4, label: "April" },
  { value: 5, label: "month of May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const listOfMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const now = new Date();
export const currentYear = now.getFullYear();
export const currentMonth = now.getMonth() + 1;

export const updateStatistics = (allQuestions, year, month, isYearlyOnly) => {
  let numberOfCardsInYear = 0;
  let numberOfCardsInMonth = 0;
  let numberOfCups = 0;
  let numberOfPentacles = 0;
  let numberOfMajorArcana = 0;
  let numberOfWands = 0;
  let numberOfSwords = 0;

  let allQuestionsFilteredByYear = [];
  let holderForAllQuestions = [];
  if (allQuestions.length > 0) {
    numberOfCardsInYear = allQuestions.filter(
      (questionCard) => questionCard.year === year
    ).length;

    allQuestionsFilteredByYear = allQuestions.filter(
      (questionCard) => questionCard.year === year
    );
  }
  if (isYearlyOnly) {
    holderForAllQuestions = [...allQuestionsFilteredByYear];
  }

  if (numberOfCardsInYear > 0 && !isYearlyOnly) {
    numberOfCardsInMonth = allQuestionsFilteredByYear.filter(
      (questionCard) => questionCard.month === month
    ).length;
    holderForAllQuestions = allQuestionsFilteredByYear.filter(
      (questionCard) => questionCard.month === month
    );
  }

  numberOfCups = holderForAllQuestions.filter(
    (questionCard) =>
      questionCard.suits.filter((card) => card === "Cups").length > 0
  ).length;
  numberOfPentacles = holderForAllQuestions.filter(
    (questionCard) =>
      questionCard.suits.filter((card) => card === "Pentacles").length > 0
  ).length;

  numberOfMajorArcana = holderForAllQuestions.filter(
    (questionCard) =>
      questionCard.suits.filter((card) => card === "Trump").length > 0
  ).length;

  numberOfWands = holderForAllQuestions.filter(
    (questionCard) =>
      questionCard.suits.filter((card) => card === "Wands").length > 0
  ).length;

  numberOfSwords = holderForAllQuestions.filter(
    (questionCard) =>
      questionCard.suits.filter((card) => card === "Swords").length > 0
  ).length;

  return {
    numberOfCardsInMonth: numberOfCardsInMonth,
    numberOfMajorArcana: numberOfMajorArcana,
    numberOfCups: numberOfCups,
    numberOfPentacles: numberOfPentacles,
    numberOfWands: numberOfWands,
    numberOfSwords: numberOfSwords,
  };
};

export const dateFormatting = (dateToFormat) => {
  const date = dayjs(dateToFormat);
  const dateToDisplay = date.format("D MMM");
  return dateToDisplay;
};

export const calculateFeelings = (totalValue, partialValue) => {
  let percentage = (partialValue / totalValue) * 100;
  if (totalValue === 0 || percentage === 0) {
    return "no";
  } else if (percentage <= 10) {
    return "a slight";
  } else if (percentage > 10 && percentage <= 20) {
    return "a mild";
  } else if (percentage > 20 && percentage <= 30) {
    return "a moderate";
  } else if (percentage > 30) {
    return "a strong";
  }
};

export function findRepeatedValues(arr) {
  const counts = {};
  const repeatedValues = {};
  const arrayOfRepeatedValues = [];

  arr.forEach((value) => {
    if (counts[value]) {
      counts[value]++;
    } else {
      counts[value] = 1;
    }
  });

  for (const value in counts) {
    if (counts[value] > 1) {
      repeatedValues[value] = counts[value];
      arrayOfRepeatedValues.push({
        cardName: value,
        cardsCount: counts[value],
      });
    }
  }

  return arrayOfRepeatedValues;
}
