import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useGetGeneralCategories } from "../../../provider/GeneralQuestionsData";
import LayoutContainer from "../../../containers/Layout";
import CategoryContainer from "./CategoryContainer";
import { generateId } from "../../../utils/generateNumber";
import Button from "../../../components/Button";
import styles from "./styles.css"

function ChooseCategory() {
  const {data: allCategoriesData }= useGetGeneralCategories();
  const queryClient = useQueryClient();
  queryClient.setQueryData(["allCategories"], allCategoriesData);
  const allCategories = queryClient.getQueryData(["allCategories"]);
  const navigate = useNavigate();
  const location = useLocation();
  const [categories, setCategories] = useState(() => []);


  const goToQuestionsPage = useCallback((categoryPath) => {
    navigate(`${location.pathname}/${categoryPath}`);
  }, [location,navigate]);;

  useEffect(() => {
    function buildCategories() {
      setCategories((prevCategory) => {
        return allCategories.map((category) => {
          return (
            <CategoryContainer
              key={generateId()}
              onClick={() => goToQuestionsPage(category.category_path.toLowerCase())}
              categoryName={category.category_name}
            />
          );
        });
      });
    }
  
    if (allCategories) {
      buildCategories();
    }
  }, [allCategories,goToQuestionsPage]);

  return (
    <LayoutContainer>
      <div className="choose_category_container" css={styles}>
        <h1>Choose the Subject You Would Like to Get Advice</h1>
        {categories}
      </div>
      <Button onClick={() => navigate("/reading-one-card")}>One Card Layout</Button>
      
      <Button onClick={() => navigate("/")}>Go To Home Page</Button>
    </LayoutContainer>
  );
}

export default ChooseCategory;
