import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import styles from "./styles.css";
import ButtonGeneral from "../Button";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../config/FirebaseConfiguration";
import { Link } from "react-router-dom";

export default function MyModal({ isOpen, closeModal, isNotLiked, saveCard }) {
  const [isLiked, setIsLiked] = useState(false);
  const userLiked = () => {
    setIsLiked(true);
    logEvent(analytics, "click_liked", "button");
  };

  return (
    <Transition appear show={isOpen} as={Fragment} css={styles}>
      <Dialog as="div" className="modal-container" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="backdrop" />
        </Transition.Child>

        <div className="fullscreen-scrollable">
          <div className="centered-container">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="modal">
                {!isLiked && !isNotLiked && (
                  <>
                    <Dialog.Title as="h3" className="modal-title">
                      🌟 Did the cards' advice resonate with you today?
                    </Dialog.Title>
                    <div className="modal-footer">
                      <ButtonGeneral onClick={userLiked}>Yes</ButtonGeneral>
                      <ButtonGeneral
                        onClick={() => closeModal("click_not_liked")}
                      >
                        Not Today
                      </ButtonGeneral>
                    </div>
                  </>
                )}
                {!isLiked && isNotLiked && (
                  <>
                    <Dialog.Title as="h3" className="modal-title">
                      🌟 Would you like to speak to Master in Cards Tarot?
                    </Dialog.Title>
                    <div className="mysticsense_recomendation">
                      Here, you get a
                      <a href="https://www.mysticsense.com/psychics/?page=1&psychic_status=1&sort=psychic_status_a#a_aid=diarytarotworld&a_bid=a44767d7">
                        {" "}
                        5 Minutes Free - Online Conversation with Real Person
                      </a>
                    </div>
                    <div className="modal-footer">
                      <a
                        className="button donate"
                        href="https://www.mysticsense.com/psychics/?page=1&psychic_status=1&sort=psychic_status_a#a_aid=diarytarotworld&a_bid=a44767d7"
                      >
                        {" "}
                        Chat
                      </a>
                      <ButtonGeneral
                        onClick={() => closeModal("click_no_chat")}
                      >
                        Not Today
                      </ButtonGeneral>
                    </div>
                  </>
                )}
                {isLiked && (
                  <div className="modal-body">
                    <Dialog.Title as="h3" className="modal-title">
                      🌟 I am excited to hear that it was helpful for you! 
                    </Dialog.Title>
                    <p className="modal-text">
                      ✨ Every contribution, whether it's feedback or a tips,
                      helps me to develop this website.
                    </p>
                    <a
                      className="button"
                      href="https://ko-fi.com/R5R7P2LKO"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Donate 💖
                    </a>
                    <Link className="button" to="/review">
                      Feedback ✍️
                    </Link>
                    <br />
                    Would you like to save your answer? It will allow you to
                    read it later, and you would be able to see what cards are
                    repeated to you each time.
                    <ButtonGeneral onClick={saveCard}>
                      Save Answer
                    </ButtonGeneral>
                    <ButtonGeneral
                      onClick={() => closeModal("click_next_time")}
                    >
                      Next Time
                    </ButtonGeneral>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
