import { useState, useMemo } from "react";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../config/FirebaseConfiguration";
import { useNavigate } from "react-router-dom";
import LayoutContainer from "../../../containers/Layout";
import { useGetQuestions } from "../../../provider/QuestionsData";
import { useAuthContext } from "../../../provider/Login/AuthProvider";
import CardsData from "../../../provider/CardsData";
import { useGetPersonalizedCards } from "../../../provider/CardsPersonalized";
import { useEffect } from "react";

import Button from "../../../components/Button";
import Select from "react-select";
import {
  years,
  months,
  currentYear,
  currentMonth,
  listOfMonths,
  updateStatistics,
  findRepeatedValues,
} from "../utils";
import StatisticsDisplay from "../components/StatisticDisplay";
import StatisticsInterpretation from "../components/StatisticsInterpretation";

export default function Statistics() {
  const navigate = useNavigate();
  const userData = useAuthContext();

  const { data: allQuestions } = useGetQuestions(
    userData ? userData.uid : "no_user"
  );
  const [yearlyStatistics, setYearlyStatistics] = useState(0);
  const [allYearlyQuestionsData, setYearlyQuestionsData] =
    useState(allQuestions);
  const [allMonthlyQuestionsData, setMonthlyQuestionsData] = useState([]);
  const [selectedYear, setSelectedYear] = useState({
    value: currentYear,
    label: currentYear,
  });
  const [selectedMonth, setSelectedMonth] = useState({
    value: currentMonth,
    label: listOfMonths[currentMonth - 1],
  });

  const [updateStatsData, setUpdateStatData] = useState(() => ({
    numberOfCardsInMonth: 0,
    numberOfMajorArcana: 0,
    numberOfCups: 0,
    numberOfPentacles: 0,
    numberOfWands: 0,
    numberOfSwords: 0,
  }));
  const { data: personalizedCards } = useGetPersonalizedCards();
  const { data: cards } = CardsData();
  const [personalizedCardsUpdated, setPersonalizedCardsUpdated] = useState([
    {
      cardImage:
        "https://firebasestorage.googleapis.com/v0/b/tarot-world-rws-deck.appspot.com/o/m07.jpg?alt=media&token=0e0dff6c-9160-4197-8907-58c172ccd3d0",
      cardName: "The Chariot",
      cardsCount: 0,
      interpretation: "",
    },
  ]);

  const statsToDisplay = useMemo(() => {
    return (
      <StatisticsDisplay
        key={`${selectedYear.value}-${selectedMonth.value}`}
        selectedYear={selectedYear}
        selectedMonth={selectedMonth}
        yearlyStatistics={yearlyStatistics}
        stats={updateStatsData}
        isYearlyOnly={false}
        personalizedCards={personalizedCardsUpdated}
      />
    );
  }, [
    selectedYear,
    selectedMonth,
    yearlyStatistics,
    updateStatsData,
    personalizedCardsUpdated,
  ]);

  const statsInterpretation = useMemo(() => {
    return (
      <StatisticsInterpretation
        key={`${selectedYear.value}-int-${selectedMonth.value}`}
        numberOfCups={updateStatsData.numberOfCups}
        numberOfMajorArcana={updateStatsData.numberOfMajorArcana}
        numberOfPentacles={updateStatsData.numberOfPentacles}
        numberOfWands={updateStatsData.numberOfWands}
        numberOfSwords={updateStatsData.numberOfSwords}
      />
    );
  }, [selectedYear, selectedMonth, updateStatsData]);

  const handleYearChange = (selectedOption) => {
    setSelectedYear(selectedOption);
    filterData(selectedOption, selectedMonth);
  };

  const handleMonthChange = (selectedOption) => {
    setSelectedMonth(selectedOption);
    filterData(selectedYear, selectedOption);
  };

  const filterData = (year, month) => {
    let yearlyFiltered;
    if (year) {
      yearlyFiltered = allQuestions.filter((item) => item.year === year.value);
      setYearlyQuestionsData(yearlyFiltered);
      setYearlyStatistics(yearlyFiltered.length);
    }

    if (year && month) {
      let monthlyFiltered = yearlyFiltered.filter(
        (item) => item.year === year.value && item.month === month.value
      );
      setMonthlyQuestionsData(monthlyFiltered);
      buildCardsData();
    }
  };

  const buildCardsData = () => {
    logEvent(analytics, "reveal_monthly_mood", "event");

    let arrayOfCards = allMonthlyQuestionsData.map(
      (question) => question.cards[0]
    );
    let repeated = findRepeatedValues(arrayOfCards);
    repeated.sort((a, b) => Number(b.cardsCount) - Number(a.cardsCount));
    const cardsWithInter = repeated.map((card) => {
      let filtered = [];
      filtered = personalizedCards.cards.filter(
        (perCard) => perCard.card === card.cardName
      );

      return {
        ...card,
        interpretation: filtered[0].interpretation,
      };
    });

    const cardsWithImages = cardsWithInter.map((card) => {
      const filtered = cards.map((perCard) => {
        if (perCard.name === card.cardName) {
          return {
            ...card,
            cardImage: perCard.img,
          };
        } else {
          return {
            ...card,
          };
        }
      });

      // Filter to get only objects with 'cardImage' property
      const filteredPArray = filtered.filter((card) =>
        card.hasOwnProperty("cardImage")
      );

      // Return the first element in the filtered array or an empty object
      return filteredPArray.length > 0 ? filteredPArray[0] : {};
    });
    setPersonalizedCardsUpdated(cardsWithImages);
  };

  useEffect(() => {
    if (allYearlyQuestionsData) {
      let filtered = allYearlyQuestionsData.filter(
        (item) => item.year === selectedYear.value
      );
      setYearlyStatistics(filtered.length);

      const updatedStatics = updateStatistics(
        allMonthlyQuestionsData,
        selectedYear.value,
        selectedMonth.value,
        false
      );
      setUpdateStatData(updatedStatics);
      if (allMonthlyQuestionsData) {
        buildCardsData();
      }
    }

    // eslint-disable-next-line
  }, [
    userData,
    allMonthlyQuestionsData,
    yearlyStatistics,
    selectedYear,
    allYearlyQuestionsData,
  ]);

  return (
    <LayoutContainer>
      <h2>Total Number of Cards that You Got</h2>
      <div className="filter-selects">
        <label>Year</label>
        <Select
          value={selectedYear}
          onChange={handleYearChange}
          options={years}
          placeholder={selectedYear.value}
        />
        <label>Month</label>
        <Select
          value={selectedMonth}
          onChange={handleMonthChange}
          options={months}
          placeholder={listOfMonths[selectedMonth.value - 1]}
        />
      </div>
      {allMonthlyQuestionsData.length > 0 ? (
        <div>
          {statsToDisplay}
          <h1>What Does the Numbers Mean:</h1>
          {statsInterpretation}
        </div>
      ) : (
        <div>Choose Month that has saved cards</div>
      )}
      <Button onClick={() => navigate("/my-tarot-diary")}>
        Go Back to My Diary
      </Button>{" "}
    </LayoutContainer>
  );
}
