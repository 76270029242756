import LayoutContainer from "../../containers/Layout";
import ButtonGeneral from "../../components/Button";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <LayoutContainer>
      <div>
        <p> This paged doesn't exists Click on </p>
        <ButtonGeneral onClick={() => navigate("/")}>
          Go Home Page
        </ButtonGeneral>
      </div>
    </LayoutContainer>
  );
}
