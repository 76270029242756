import LayoutContainer from "../../containers/Layout";
import React from "react";
import MetaTags from "../../components/MetaTags";

export default function AboutMe() {
  return (
    <LayoutContainer>
      <div className="autobiography">
        <h2 className="about-header">About Me and "The World of Tarot"</h2>
        <p>
          {" "}
          My name is Lina, and I have been immersed in the world of Tarot since
          1999. Tarot has been a source of immense help and guidance for me ever
          since I turned 18. During that time, I was fortunate to have a highly
          skilled card reader who consistently guided me along the right path in
          life. Her insights and wisdom have made a profound impact on my
          journey.
        </p>
        <p>
          Additionally, I have a deep passion for technology, and my exploration
          of web development began in 2022. Recognizing the potential for
          synergy between Tarot and IT technology, I embarked on a mission to
          combine the two and create a Tarot reading website. It was a natural
          progression for me to merge my love for Tarot and my technological
          interests.
        </p>
        <p>
          As my website evolved, I started integrating AI as a helpful tool in
          my Tarot readings. The introduction of AI technology has allowed me to
          enhance the user experience and offer additional insights to those
          seeking guidance. This integration of AI into my Tarot website is a
          recent development, and I am excited to explore the possibilities it
          brings.
        </p>
        <p>
          I believe that through this unique blend of ancient wisdom and
          cutting-edge technology, I can provide a valuable resource for
          individuals seeking clarity and guidance in their lives.
        </p>
        <p>
          Nevertheless, I firmly believe that AI can never replicate the
          personalized touch of a human Tarot reader. Tarot readings encompass
          comprehending not only the symbolism and significance of the cards but
          also the capacity to empathize and establish connections with those in
          need of guidance. Creating personal connections forms an essential
          part of the Tarot reading experience.
        </p>
        <p>*I am not doing a personal readings.*</p>
        <p>
          {" "}
          I would like to hear your opinion about the website. If you have any
          feedback. You could share with me how the Tarot Diary website helped
          you.
        </p>
        <p>
          You can contact me by{" "}
          <a href="mailto:diarytarotworld@gmail.com?subject=Tarot%20World&amp;">
            Sending an email
          </a>
        </p>
        <MetaTags
          description={
            "Meet Lina, a Tarot enthusiast since 1999 with a deep passion for technology. Her journey from Tarot to web development led to the creation of a unique Tarot website that integrates AI for enhanced readings and guidance. Explore the synergy of mysticism and technology with Lina."
          }
        />
      </div>
    </LayoutContainer>
  );
}
