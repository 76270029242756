import firebaseApp from "../config/FirebaseConfiguration.js";
import {
  getFirestore,
  collection,
  addDoc,
} from "firebase/firestore/lite";
import { useMutation } from "@tanstack/react-query";
const db = getFirestore(firebaseApp);

export function useCreateReviews() {
  return useMutation(async ({ date, feedback }) => {
    addDoc(collection(db, "reviews"), {
      date: date,
      feedback: feedback,
    });
  });
}
