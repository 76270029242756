import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../config/FirebaseConfiguration";
import styles from "./styles.css";
import CustomLink from "../Links";
import { useEffect } from "react";
import icon from "../../images/esoteric.png";
import profile from "../../images/profileCat.png";
import ButtonGeneral from "../Button";
import { useAuthContext } from "../../provider/Login/AuthProvider";

export default function NavAppBar({ children }) {
  const [isDropdownOpenSpread, setIsDropdownOpenSpread] = useState(false);
  const [isDropdownOpenLearn, setIsDropdownOpenLearn] = useState(false);
  const [isDropdownOpenProfile, setIsDropdownOpenProfile] = useState(false);
  const [isDropdownAboutMe, setIsDropdownOpenAboutMe] = useState(false);

  const navigate = useNavigate();
  //const queryClient = useQueryClient();
 // const userData = queryClient.getQueryData(["userData"]);
  const userData = useAuthContext();
  const [isLoading, setIsLoading] = useState(true);
  const toggleDropdownSpread = () => {
    setIsDropdownOpenSpread(!isDropdownOpenSpread);
    setIsDropdownOpenLearn(false);
    setIsDropdownOpenProfile(false);
    setIsDropdownOpenAboutMe(false);
  };
  const toggleDropdownLearn = () => {
    setIsDropdownOpenLearn(!isDropdownOpenLearn);
    setIsDropdownOpenSpread(false);
    setIsDropdownOpenProfile(false);
    setIsDropdownOpenAboutMe(false);
  };
  const toggleDropdownProfile = () => {
    setIsDropdownOpenProfile(!isDropdownOpenProfile);
    setIsDropdownOpenSpread(false);
    setIsDropdownOpenLearn(false);
    setIsDropdownOpenAboutMe(false);
  };
  const toggleDropdownAboutMe = () => {
    setIsDropdownOpenAboutMe(!isDropdownAboutMe);
    setIsDropdownOpenProfile(false);
    setIsDropdownOpenSpread(false);
    setIsDropdownOpenLearn(false);
  };

  const handleLogout = async () => {
    await signOut(auth);
    navigate("/");

    //queryClient.clear(["userData"]);
  };

  useEffect(() => {
    const checkAuthentication = async () => {
      await new Promise((resolve) => setTimeout(resolve, 50));
      setIsLoading(false);
    };
    checkAuthentication();
  }, [userData]);

  return (
    <header>
      <img className="navbar-image" src={icon} alt="navbar-icon" />
      <Link className="site-title" to="/">
        Tarot Diary
      </Link>

      <input className="side-menu" type="checkbox" id="side-menu" />
      <label className="hamb" htmlFor="side-menu">
        <span className="hamb-line"></span>
      </label>

      <nav className="nav" css={styles}>
        <div className="menu">
          <div onClick={toggleDropdownSpread}>
            Get Advice From Tarot
            <button onClick={toggleDropdownSpread} className="arrow-button">
              {isDropdownOpenSpread ? "▲" : "▼"}
            </button>
            {isDropdownOpenSpread && (
              <div className="dropdown-menu">
                <CustomLink to="/reading-one-card">
                  Get Advice for Your Situation
                </CustomLink>
                <CustomLink to="/choose-category">
                  Choose Question and Get an Answer
                </CustomLink>
                <CustomLink to="/reading-three-cards-layout">
                  Get Clarity for Past,Present and Future
                </CustomLink>
                <CustomLink to="/reading-seven-cards-layout">
                  Get Clarity on "Whom You Need to Become in one Year?" - Seven Cards Layout 
                </CustomLink>
                <CustomLink to="/reading-yes-no-layout">
                  Yes, No or Maybe
                </CustomLink>
                <CustomLink to="/reading-year-layout">
                 2024 Year by Month
                </CustomLink>
              </div>
            )}
          </div>

          <div onClick={toggleDropdownLearn}>
            Learn Cards
            <button onClick={toggleDropdownLearn} className="arrow-button">
              {isDropdownOpenLearn ? "▲" : "▼"}
            </button>
            {isDropdownOpenLearn && (
              <div className="dropdown-menu">
                <CustomLink to="/learn-cards">Learn Cards</CustomLink>
                <CustomLink to="/learn-cards/quizzes">
                  Test your Knowledge for Tarot Cards
                </CustomLink>
                <CustomLink to="/articles">Articles - Topics to Think About</CustomLink>
                <CustomLink to="/search-cards">Search for Cards</CustomLink>
              </div>
            )}
          </div>

          <div onClick={toggleDropdownAboutMe}>
            Contact
            <button onClick={toggleDropdownAboutMe} className="arrow-button">
              {isDropdownAboutMe ? "▲" : "▼"}
            </button>
            {isDropdownAboutMe && (
              <div className="dropdown-menu">
                <CustomLink to="/about-me">About and Contact</CustomLink>
                <CustomLink to="/support-me">Buy me a Coffee</CustomLink>
              </div>
            )}
          </div>
          {isLoading? (<span>Loading..</span>): (!userData) ?  (
            <CustomLink to="/login">Login</CustomLink>
          ) : (
            <div onClick={toggleDropdownProfile}>
              <img className="my-profile-img" src={profile} alt="my profile" />
              <button onClick={toggleDropdownProfile} className="arrow-button">
                {isDropdownOpenProfile ? "▲" : "▼"}
              </button>
              {isDropdownOpenProfile && (
                <div className="dropdown-menu">
                  <CustomLink to="/my-tarot-diary">My Tarot Diary</CustomLink>
                  <ButtonGeneral onClick={handleLogout}>Logout</ButtonGeneral>
                </div>
              )}
            </div>
          )}
        </div>
      </nav>
      <div className="google-translate" id="google_translate_element"></div>
    </header>
  );
}
